import { useLazyQuery } from "@apollo/client";
import {
    FetchMapUpdatesDocument,
    type FilterObject,
    type InputPolygon,
    type InputRadius,
    type InputViewBox,
    MultiPolygon,
    type NewFilterObjectConnection,
    type SubscriptionResponse,
} from "@biggeo/bg-server-lib/datascape-ai";
import * as A from "fp-ts/Array";
import { pipe } from "fp-ts/lib/function";
import isEmpty from "lodash/isEmpty";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import uuid from "react-uuid";
import { v4 as uuidV4 } from "uuid";
import { commonActions } from "../../common/redux/model.ts";
import { getMultiFilters } from "../mapbox/context/context-utils";
import { useMap } from "../mapbox/context/map.ts";
import { SavedPolygonType } from "../mapbox/hooks/saved-polygon-hooks";
import { removeCustomLayers } from "../utils/style-utils.ts";
import {
    useLatestMapChangesV2,
    useServerWebsocket,
} from "../utils/subscription";
import { FunctionType } from "../utils/utils";

const channelId = uuid();

export type InputPolygonWithId = InputPolygon & {
    readonly id: string;
};

export type InputRadiusWithId = InputRadius & {
    readonly id: string;
};

export type PureDataStringHookProps = {
    readonly functionType: FunctionType;
    readonly setFunctionType: React.Dispatch<
        React.SetStateAction<FunctionType>
    >;
    readonly polygons: InputPolygonWithId[];
    readonly setPolygons: React.Dispatch<
        React.SetStateAction<InputPolygonWithId[]>
    >;
};

export type PureDataStringHookReturnType = {
    polygons: InputPolygonWithId[];
    handleMultiPolygons: ({
        polygon,
    }: {
        polygon: InputPolygonWithId | InputPolygonWithId[];
    }) => void;
    clearData: () => void;
    responses: Partial<Record<string, SubscriptionResponse | null>>;
    channelId: string;
    multiFilters: FilterObject[];
    handlePolygonsOnZoom: (p: InputPolygon[]) => void;
    handleViewportChange: ({
        viewport,
    }: {
        viewport: InputViewBox;
    }) => void;
    viewport: InputViewBox;
    recentResponse?: SubscriptionResponse;
    savedPolygons: SavedPolygonType;
    setSavedPolygons: (i: Partial<SavedPolygonType>) => void;
    functionType: FunctionType;
    setFunctionType: (f: FunctionType) => void;
    deleteShape: (id: string) => void;
    clearShapes: () => void;
    handleSavedPolygons: (p: InputPolygonWithId[]) => void;
    removeBoundaryById: (id: number) => void;
};

export const usePureDataString = (): PureDataStringHookReturnType => {
    const socket = useServerWebsocket();
    const dispatch = useDispatch();

    const [responses, setResponses] = useState<
        Partial<Record<string, SubscriptionResponse | null>>
    >({});

    const [recentResponse, setRecentResponse] =
        useState<SubscriptionResponse>();

    const {
        dispatch: mapDispatch,
        draw,
        map,
        isLoaded,
        selectedShapes,
        viewport,
        polygons,
        functionType,
        savedPolygons,
        mapState,
    } = useMap();

    const multiFilters = getMultiFilters();

    const setDataLoading = (loading: boolean) => {
        mapDispatch?.({
            type: "SET_DATA_LOADING",
            values: { state: loading },
        });
    };

    const setFunctionType = (f: FunctionType) => {
        mapDispatch?.({
            type: "SET_MAP",
            values: { functionType: f },
        });
    };

    const setViewport = (p: InputViewBox) => {
        mapDispatch?.({
            type: "SET_MAP",
            values: { viewport: p },
        });
    };

    const setSavedPolygons = (s: SavedPolygonType) => {
        mapDispatch?.({
            type: "SET_MAP",
            values: { savedPolygons: s },
        });
    };

    const clearShapes = () => {
        mapDispatch?.({
            type: "SET_POLYGONS",
            values: { p: [] },
        });
    };

    const clearData = () => {
        setResponses({});
    };

    useLatestMapChangesV2(channelId, (response) => {
        setResponses({ ...responses, [response.databaseId]: response });
        setRecentResponse(response);
    });

    const [execute, { loading: filteredDataLoading }] = useLazyQuery<
        { fetchMapUpdates: boolean },
        { input: NewFilterObjectConnection }
    >(FetchMapUpdatesDocument);

    const sendMultiPolygons = (
        polygons: (InputPolygonWithId | InputPolygon)[]
    ) => {
        pipe(
            multiFilters,
            A.map((item) => {
                dispatch(
                    commonActions.setSearchStartTime({
                        datasetId: item.databaseId,
                        startTime: Date.now(),
                    })
                );
            })
        );

        const multipolygon: MultiPolygon = {
            polygons: polygons.map((p) => ({
                inners: p.inners,
                outer: p.outer,
            })),
        };

        setDataLoading(true);

        socket.emit(
            "updateViewPortV2",
            {
                dateTime: Date.now().toString(),
                channel: channelId,
                multiFilters,
                requestId: uuidV4(),
                isStreaming: false,
                multipolygon,
            },
            // biome-ignore lint/suspicious/noExplicitAny: <explanation>
            (response: any) => {
                if (response.success || response.error) {
                    setDataLoading(false);
                }
            }
        );

        if (multiFilters.some((d) => d.filters.length > 0)) {
            setDataLoading(true);

            execute({
                variables: {
                    input: {
                        multipolygon: multipolygon.polygons,
                        dateTime: Date.now().toString(),
                        channelId: channelId,
                        filters: multiFilters,
                    },
                },
                onCompleted: () => {
                    setDataLoading(false);
                },
                onError: () => {
                    setDataLoading(false);
                },
            });
        }
    };

    socket.on("message-back", () => {
        pipe(
            multiFilters,
            A.map((item) => {
                dispatch(
                    commonActions.setSearchEndTime({
                        datasetId: item.databaseId,
                        endTime: Date.now(),
                    })
                );
            })
        );
    });

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        setDataLoading(filteredDataLoading);
    }, [filteredDataLoading]);

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        if (isEmpty(polygons)) {
            handleViewportChange({ viewport });
        } else {
            sendMultiPolygons(polygons);
        }
    }, [polygons]);

    const handleMultiPolygons = ({
        polygon,
    }: {
        polygon: InputPolygonWithId | InputPolygonWithId[];
    }) => {
        mapDispatch?.({
            type: "SET_POLYGONS",
            values: { p: polygon },
        });
    };

    const removeBoundaryById = (id: number) => {
        const polygon = polygons.find((p) => p.properties?.savedAreaId === id);

        if (polygon) {
            deleteShape(polygon.id);
        }
    };

    const deleteShape = (id: string) => {
        mapDispatch?.({
            type: "DELETE_POLYGON",
            values: { id },
        });

        if (map.current && draw.current && isLoaded) {
            const feature = mapState?.featureCollection?.features.find(
                (f) => f.id === id
            );

            draw.current.delete(id);

            if (feature) {
                mapDispatch?.({
                    type: "SET_PREVIOUS_STATE",
                    values: {
                        type: "draw.delete",
                        feature,
                    },
                });

                if (selectedShapes.features.map((f) => f.id).includes(id)) {
                    mapDispatch?.({
                        type: "SET_SELECTED_SHAPES",
                        values: feature,
                    });
                }
            }

            removeCustomLayers(map.current);
        }
    };

    const handleSavedPolygons = (p: InputPolygonWithId[]) => {
        mapDispatch?.({
            type: "SET_POLYGONS",
            values: { p },
        });
    };

    const handlePolygonsOnZoom = (p: InputPolygon[]) => {
        /* Shouldn't set the polygons state because it relies on it to calculate the
         * union polygon and intersections.
         */
        sendMultiPolygons(p);
    };

    const handleViewportChange = ({ viewport }: { viewport: InputViewBox }) => {
        if (!isEmpty(polygons)) return;
        setViewport(viewport);

        pipe(
            multiFilters,
            A.map((item) => {
                dispatch(
                    commonActions.setSearchStartTime({
                        datasetId: item.databaseId,
                        startTime: Date.now(),
                    })
                );
            })
        );

        socket.emit("updateViewPortV2", {
            viewport,
            dateTime: Date.now().toString(),
            channel: channelId,
            multiFilters,
            requestId: uuidV4(),
            isStreaming: false,
        });

        if (multiFilters.some((d) => d.filters.length > 0)) {
            setDataLoading(true);
            execute({
                variables: {
                    input: {
                        viewport,
                        dateTime: Date.now().toString(),
                        channelId: channelId,
                        filters: multiFilters,
                    },
                },
                onCompleted: () => {
                    setDataLoading(false);
                },
                onError: () => {
                    setDataLoading(false);
                },
            });
        }
    };

    return {
        polygons,
        handleMultiPolygons,
        clearData,
        responses,
        channelId,
        multiFilters,
        handleViewportChange,
        viewport,
        recentResponse,
        savedPolygons,
        setSavedPolygons: (value) => {
            setSavedPolygons({ ...savedPolygons, ...value });
        },
        setFunctionType,
        functionType,
        deleteShape,
        clearShapes,
        handleSavedPolygons,
        handlePolygonsOnZoom,
        removeBoundaryById,
    };
};
