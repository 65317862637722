import {
    Button,
    CellItem,
    CheckboxMenuItem,
    Divider,
    DraggableListItem,
    ExitIntentModal,
    IconButton,
    Menu,
    MenuItem,
    OverflowingTypography,
    Stack,
    Switch,
    Tooltip,
    useTheme,
} from "@biggeo/bg-ui/lab";
import {
    DragIndicatorOutline,
    EditOutline,
    MoreVertOutline,
    TuneOutline,
    VisibilityOffOutline,
    VisibilityOutline,
} from "@biggeo/bg-ui/lab/icons";
import { ComponentClassNames } from "@biggeo/bg-ui/lab/system";
import isEmpty from "lodash/isEmpty";
import isEqual from "lodash/isEqual";
import { useDispatch } from "react-redux";
import { compose } from "redux";
import { handleFilteredDatasets } from "../../map/filter-criteria/utils/utils";
import { MapContextDataset, useMap } from "../../map/mapbox/context";
import { SetDatasetContextType } from "../../map/mapbox/context/context-utils";
import { setDatasetVisibility } from "../../map/mapbox/utils/data-layers-utils";
import { getDatasetShapeColor } from "../../map/utils/style-utils";
import { modalActions } from "../../modal/redux/model";
import { ShapeIcon } from "./ShapeIcon";

// eslint-disable-next-line react-refresh/only-export-components
export const toggleDatasetCellItemClasses: ComponentClassNames<
    "ToggleDatasetCellItem",
    "drag-indicator" | "visibility-icon"
> = {
    "drag-indicator": "BigGeoToggleDatasetCellItem-dragIndicator",
    "visibility-icon": "BigGeoToggleDatasetCellItem-visibilityIcon",
};

export type ToggleDatasetCellItemProps = Pick<
    SetDatasetContextType,
    "updateDataset"
> & {
    readonly index: number;
    readonly item: MapContextDataset;
    readonly onItemClick: () => void;
    readonly manageCompute: () => void;
    readonly gradient?: string;
    readonly disabled?: boolean;
};

export const ToggleDatasetCellItem = ({
    index,
    item,
    onItemClick,
    manageCompute,
    gradient,
    disabled,
    updateDataset,
}: ToggleDatasetCellItemProps) => {
    const theme = useTheme();
    const { dispatch, map, isLoaded, filters } = useMap();

    const reduxDispatch = useDispatch();
    const openModal = compose(reduxDispatch, modalActions.openModal);
    const closeModal = compose(reduxDispatch, modalActions.closeModal);

    const isVisible = item.isVisible;
    const isSelected = item.isSelected;
    const isLegendOpen = item.isLegendOpen;
    const isConfigurationOpen = item.configuration.isOpen;

    const { fill, stroke } = getDatasetShapeColor(item);

    const unselectDataset = (dataSourceId: string) => {
        updateDataset({
            dataSourceId,
            dataset: {
                isSelected: false,
                isVisible: false,
                isLegendOpen: false,
                isTableViewed: false,
                configuration: {
                    isOpen: false,
                },
            },
        });
    };

    const open = ({
        onClickClearFilters,
        onClickKeepFilters,
        filters,
        datasetId,
    }: {
        onClickClearFilters: () => void;
        onClickKeepFilters: () => void;
        filters: string[];
        datasetId: string;
    }) => {
        openModal({
            modalType: "new-dialog",
            component: (
                <ExitIntentModal
                    withLoading={false}
                    subtitle={
                        <>
                            <span
                                style={{
                                    color: theme.palette.background.onMain,
                                }}
                            >
                                {filters.join(", ")}
                            </span>{" "}
                            filter criteria's that require the compute
                            resources. Turning the compute off will clear these
                            filters, are you sure you want to proceed?
                        </>
                    }
                    discard={() => {
                        // Keep the filters and don't unselect the dataset
                        onClickKeepFilters();
                    }}
                    save={() => {
                        // Clear the filters and unselect the dataset
                        onClickClearFilters();
                        unselectDataset(datasetId);
                    }}
                    buttons={{
                        text1: "Keep Filters",
                        text2: "Yes, Clear Filters",
                    }}
                />
            ),
            dialogProps: {
                variant: "centered-small",
                onClose: undefined,
            },
        });
    };

    const removeSelectedDataset = (dataSourceId: string) => {
        if (isEmpty(filters)) {
            unselectDataset(dataSourceId);
        } else {
            handleFilteredDatasets({
                map,
                isLoaded,
                dispatch,
                unselectDataset: () => {
                    unselectDataset(dataSourceId);
                },
                filters,
                dataset: item,
                openModal: (i) => open({ ...i, datasetId: dataSourceId }),
                closeModal,
            });
        }
    };

    const addSelectedDataset = (dataSourceId: string) => {
        updateDataset({
            dataSourceId,
            dataset: {
                isSelected: true,
                isVisible: true,
            },
        });
    };

    const handleVisibility = (dataSourceId: string) => {
        if (map.current) {
            setDatasetVisibility({
                map: map.current,
                isLoaded,
                prefix: dataSourceId,
                levelSets: [],
                visibility: isVisible ? "none" : "visible",
                styles: item.mapTemplateDataset?.styles || undefined,
            });
        }

        updateDataset({
            dataSourceId,
            dataset: {
                isVisible: !isVisible,
            },
        });
    };

    const handleLegend = (dataSourceId: string) => {
        updateDataset({
            dataSourceId,
            dataset: {
                isLegendOpen: !isLegendOpen,
            },
        });
    };

    const handleConfiguration = (dataSourceId: string) => {
        updateDataset({
            dataSourceId,
            dataset: {
                configuration: {
                    isOpen: !isConfigurationOpen,
                },
            },
        });
    };

    return (
        <DraggableListItem
            index={index}
            draggableId={item.dataSource.id}
            slotProps={{
                container: {
                    sx: {
                        padding: 0,
                        backgroundColor: (theme) =>
                            theme.palette.background.main,
                    },
                },
            }}
            configuration={{
                alwaysShowDragHandle: true,
            }}
            sx={{
                borderRadius: (theme) => theme.radius.default,
                overflow: "hidden",
                border: 1,
                borderColor: (theme) => theme.palette.stroke[100],
                background: `${gradient}`,
                paddingTop: 2,
                "&:hover": {
                    [`& .${toggleDatasetCellItemClasses["visibility-icon"]}`]: {
                        display: "block",
                    },
                },
            }}
        >
            <Tooltip
                placement="right-start"
                title={
                    isEqual(item.dataSource.compute || undefined, true)
                        ? "This dataset is currently using your compute resources."
                        : "Compute resources are currently off for this dataset."
                }
                bodyNode={
                    <Button
                        variant="outlined"
                        density="dense"
                        onClick={manageCompute}
                    >
                        Manage Compute
                    </Button>
                }
                disableHover={Boolean(item.dataSource.compute && !isSelected)}
                disableFocus={Boolean(item.dataSource.compute && !isSelected)}
                sx={{
                    maxWidth: (theme) => theme.spacing(40),
                }}
            >
                <span>
                    <CellItem
                        disableHoverEffect
                        disableActiveEffect
                        key={item.dataSource.id}
                        density="none"
                        title={
                            <OverflowingTypography variant="body3">
                                {item.dataSource.label ||
                                    item.dataSource.tableName}
                            </OverflowingTypography>
                        }
                        slotProps={{
                            title: {
                                fontWeight: "regular",
                            },
                        }}
                        sx={{
                            padding: 1,
                            opacity: disabled ? 0.25 : 1,
                            pointerEvents: disabled ? "none" : "auto",
                        }}
                        readonly={
                            !item.dataSource.compute ||
                            isEqual(item.dataSource.compute, false)
                        }
                        startNode={
                            <Stack
                                flexDirection="row"
                                gap={2}
                                alignItems="center"
                            >
                                <DragIndicatorOutline
                                    className={
                                        toggleDatasetCellItemClasses[
                                            "drag-indicator"
                                        ]
                                    }
                                    sx={{ display: "none", cursor: "grab" }}
                                />
                                <Switch
                                    id={item.dataSource.id}
                                    switched={isSelected}
                                    disabled={
                                        !item.dataSource.compute ||
                                        isEqual(item.dataSource.compute, false)
                                    }
                                    onSwitchChange={() => {
                                        isSelected
                                            ? removeSelectedDataset(
                                                  item.dataSource.id
                                              )
                                            : addSelectedDataset(
                                                  item.dataSource.id
                                              );
                                    }}
                                />
                                <ShapeIcon oval fill={fill} stroke={stroke} />
                            </Stack>
                        }
                        endNode={
                            <Stack
                                flexDirection="row"
                                gap={2}
                                alignItems="center"
                            >
                                <IconButton
                                    variant="minimal"
                                    className={
                                        toggleDatasetCellItemClasses[
                                            "visibility-icon"
                                        ]
                                    }
                                    onClick={() => {
                                        handleVisibility(item.dataSource.id);
                                    }}
                                    disabled={!isSelected}
                                    sx={{ display: "none" }}
                                >
                                    {isSelected && isVisible ? (
                                        <VisibilityOutline size="xs" />
                                    ) : (
                                        <VisibilityOffOutline size="xs" />
                                    )}
                                </IconButton>
                                <Menu
                                    placement="bottom-end"
                                    content={
                                        <>
                                            <MenuItem
                                                key="style-dataset"
                                                density="dense"
                                                label="Style dataset"
                                                onClick={onItemClick}
                                                startNode={<EditOutline />}
                                            />
                                            <MenuItem
                                                key="configuration"
                                                density="dense"
                                                label="Configuration"
                                                startNode={<TuneOutline />}
                                                onClick={() => {
                                                    handleConfiguration(
                                                        item.dataSource.id
                                                    );
                                                }}
                                            />
                                            <Divider />
                                            <CheckboxMenuItem
                                                key="legend"
                                                density="dense"
                                                label="Legend"
                                                selected={isLegendOpen}
                                                disabled={!isSelected}
                                                onClick={() => {
                                                    handleLegend(
                                                        item.dataSource.id
                                                    );
                                                }}
                                            />
                                        </>
                                    }
                                >
                                    <IconButton variant="minimal">
                                        <MoreVertOutline />
                                    </IconButton>
                                </Menu>
                            </Stack>
                        }
                    />
                </span>
            </Tooltip>
        </DraggableListItem>
    );
};
