import { Industry } from "@biggeo/bg-server-lib/studio";

export const user = {
    firstName: "Olivia",
    lastName: "Bergson",
    email: "olivia@northstarsystems.com",
    img: "https://randomuser.me/api/portraits/women/2.jpg",
    phoneCountryCode: "1",
    phone: "4015691234",
    currentWorkspace: {
        name: "Northstar Systems Canada",
        img: "https://biggeo.blob.core.windows.net/media/bg-background.png",
        industry: [Industry.PublicSectorAndGovernment],
        country: "Canada",
        phone: "4031231234",
        website: "www.northstarsystems.com",
        description: undefined,
    },
    workspaces: [
        {
            name: "Northstar Systems Canada",
            img: "https://biggeo.blob.core.windows.net/media/bg-background.png",
        },
        {
            name: "Northstar Systems USA",
            img: "https://biggeo.blob.core.windows.net/media/bg-background.png",
        },
    ],
    role: "owner",
};
