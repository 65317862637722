import {
    FlexScrollArea,
    FlexScrollAreaContainer,
    SelectableTreeMenuItem,
    Stack,
} from "@biggeo/bg-ui/lab";
import * as O from "fp-ts/Option";
import * as A from "fp-ts/lib/Array";
import { pipe } from "fp-ts/lib/function";
import isEmpty from "lodash/isEmpty";
import isEqual from "lodash/isEqual";
import { useEffect } from "react";
import { useNavigate } from "react-router";
import { SetURLSearchParams } from "react-router-dom";
import { match } from "ts-pattern";
import { MapHeader } from "../../components/MapHeader";
import {
    MarketplaceMapHeader,
    TabValues,
} from "../../components/MarketplaceMapHeader";
import ExportToSFPage from "../../marketplace/pages/ExportToSFPage";
import MapConfiguration from "../containers/MapConfiguration";
import MapSavedViews from "../containers/MapSavedViews";
import MapMainContainer from "../mapbox/containers/MapMainContainer";
import {
    IMapViewWrapper,
    MapTableTabs,
    MapTableTabsType,
    MapTabs,
} from "./MapViewWrapper";

export interface IMapViewPage extends IMapViewWrapper, MapTableTabsType {
    readonly handleMapTabs: (tab: MapTabs) => void;
    readonly savedAreaId: number;
    readonly savedViewId?: number;
    readonly filterItems: SelectableTreeMenuItem[];
    readonly setFilterItems: React.Dispatch<
        React.SetStateAction<SelectableTreeMenuItem[]>
    >;
    readonly setSearchParams: SetURLSearchParams;
    readonly searchParams: URLSearchParams;
}

const MapViewPage = ({
    currentTab,
    activeConsumption,
    setConsumption,
    mapTemplateId,
    isSavedAreaForm,
    isFromMarketplace,
    isExporting,
    savedViewId,
    savedAreaId,
    setIsExporting,
    handleMapTabs,
    setFilterItems,
    filterItems,
    searchParams,
    setSearchParams,
    setOpenSaveViewPopper,
    ...props
}: IMapViewPage) => {
    const toPage = useNavigate();
    const tab = currentTab ?? MapTabs.map;

    const selectedDatasets = pipe(
        props.datasets,
        A.filter((d) => isEqual(d.isSelected, true))
    );

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        if (
            isEmpty(selectedDatasets) &&
            !isEqual(props.tab, MapTableTabs.map) &&
            isEqual(props.tab, MapTableTabs.table)
        ) {
            props.setTab(MapTableTabs.map);
        }
    }, [selectedDatasets.length]);

    return (
        <Stack height="100%" width="100%">
            {pipe(
                isExporting,
                O.fromPredicate((x) => Boolean(x)),
                O.foldW(
                    () => (
                        <FlexScrollAreaContainer width="100%">
                            {isFromMarketplace ? (
                                <MarketplaceMapHeader
                                    currentTab={TabValues.mapView}
                                />
                            ) : (
                                <MapHeader
                                    currentTab={tab}
                                    handleMapTabs={handleMapTabs}
                                    saveView={() =>
                                        setOpenSaveViewPopper(
                                            !props.openSaveViewPopper
                                        )
                                    }
                                    isExporting={isExporting}
                                    setIsExporting={setIsExporting}
                                />
                            )}
                            <FlexScrollArea flexDirection="column">
                                {match(tab)
                                    .with(MapTabs.configuration, () => (
                                        <MapConfiguration
                                            mapTemplateId={mapTemplateId}
                                            toPage={toPage}
                                            isSavedAreaForm={isSavedAreaForm}
                                        />
                                    ))
                                    .with(MapTabs.savedViews, () => (
                                        <MapSavedViews
                                            mapTemplateId={mapTemplateId}
                                            toPage={toPage}
                                        />
                                    ))
                                    .with(MapTabs.map, () => (
                                        <MapMainContainer
                                            {...props}
                                            mainTab={tab}
                                            mapTemplateId={mapTemplateId}
                                            activeConsumption={
                                                activeConsumption
                                            }
                                            setConsumption={setConsumption}
                                            setOpenSaveViewPopper={
                                                setOpenSaveViewPopper
                                            }
                                            isFromMarketplace={
                                                isFromMarketplace
                                            }
                                            savedAreaId={savedAreaId}
                                            savedViewId={savedViewId}
                                            searchParams={searchParams}
                                            setSearchParams={setSearchParams}
                                            filterItems={filterItems}
                                            setFilterItems={setFilterItems}
                                        />
                                    ))
                                    .exhaustive()}
                            </FlexScrollArea>
                        </FlexScrollAreaContainer>
                    ),
                    () => <ExportToSFPage setIsExporting={setIsExporting} />
                )
            )}
        </Stack>
    );
};

export default MapViewPage;
