import {
    type FilterObject,
    type SubscriptionResponse,
} from "@biggeo/bg-server-lib/datascape-ai";
import { toNonReadonlyArray } from "@biggeo/bg-utils";
import * as A from "fp-ts/lib/Array";
import { pipe } from "fp-ts/lib/function";
import every from "lodash/every";
import gt from "lodash/gt";
import isEmpty from "lodash/isEmpty";
import isEqual from "lodash/isEqual";
import size from "lodash/size";
import type mapboxgl from "mapbox-gl";
import { useEffect } from "react";
import { useDispatch } from "react-redux";

import { useRenderingTimes } from "../../../common/redux/hooks";
import { commonActions } from "../../../common/redux/model";
import { updateDatasetsVisibility } from "../../filter-criteria/utils/utils";
import { MapContextFilter, useMap } from "../context";
import { getGeometryFromData } from "../utils/data-hooks-utils";
import {
    getDatasetSources,
    getDatasetStyles,
    handleDatasetLayers,
} from "../utils/data-layers-utils";
import { setSelectedRowPulsingDot } from "../utils/map-utils";
import { DEFAULT_SHAPE_COLOR } from "./style-hooks";

export type DataHookProps = {
    readonly map: React.MutableRefObject<mapboxgl.Map | null>;
    readonly multiFilters: FilterObject[];
    readonly recentResponse: SubscriptionResponse | undefined;
    readonly style?: mapboxgl.Style;
    readonly isLoaded: boolean;
    readonly filters: MapContextFilter[];
    readonly isSavedViewPage: boolean;
};

export const useData = ({
    map,
    multiFilters,
    recentResponse,
    style,
    isLoaded,
    isSavedViewPage,
}: DataHookProps) => {
    const dispatch = useDispatch();
    const renderingTimes = useRenderingTimes();

    const { datasets, dispatch: mapDispatch } = useMap();

    const selectedRows = pipe(
        datasets,
        A.flatMap((d) => d.selectedRows)
    );

    const numberOfSelectedDatasets = pipe(
        datasets,
        A.filter((d) => d.isSelected),
        size
    );

    const allRenderingTimesGreaterThanZero = every(
        renderingTimes,
        (r) => r.layerRenderingTime > 0
    );

    // const hasVisibleFilters = !isEmpty(
    //     pipe(
    //         filters,
    //         A.filter(
    //             (f) => isEqual(f.visible, true) && !isEmpty(f.filterCriteria)
    //         )
    //     )
    // );
    // const hasSelectedFilters = !isEmpty(
    //     pipe(
    //         filters,
    //         A.filter((f) => isEqual(f.selected, true))
    //     )
    // );

    const filters = pipe(
        datasets,
        A.filter((f) => !isEmpty(f.filters)),
        A.flatMap((f) => f.filters?.filters || [])
    );

    const onStyleLoad = (map: mapboxgl.Map) => {
        map.addSource("polygons-from-ai", {
            type: "geojson",
            data: {
                type: "FeatureCollection",
                features: [],
            },
        });

        map.addLayer({
            type: "circle",
            source: "polygons-from-ai",
            paint: {
                "circle-radius": 3,
                "circle-color": DEFAULT_SHAPE_COLOR,
            },
            id: "polygons-from-ai-layer",
        });
    };

    const isRenderingTimePositive = (datasetId: string) =>
        pipe(
            renderingTimes,
            toNonReadonlyArray,
            A.some(
                (item) =>
                    isEqual(item.datasetId, datasetId) &&
                    gt(item.layerRenderingTime, 0)
            )
        );

    const setDatasetsFromResponse = (input: {
        response: SubscriptionResponse;
        map: mapboxgl.Map;
        isLoaded: boolean;
    }) => {
        const { response, map, isLoaded } = input;

        const dataSourceId = response.databaseId;
        const data = response.geometry || undefined;

        const isFiltered = multiFilters.find(
            (m) => m.databaseId === dataSourceId
        );

        // const isNotPreviewed = pipe(
        //     filters,
        //     O.fromPredicate((x) => !isEmpty(x)),
        //     O.fold(
        //         () => true,
        //         (f) =>
        //             pipe(
        //                 f,
        //                 A.findFirst((f) => isEqual(f.preview, true)),
        //                 O.fold(
        //                     () => true,
        //                     (previewedFilter) =>
        //                         pipe(
        //                             previewedFilter.filterCriteria,
        //                             A.findFirst(
        //                                 (i) => i.dataSourceId !== dataSourceId
        //                             ),
        //                             O.fold(
        //                                 () => true,
        //                                 () => false
        //                             )
        //                         )
        //                 )
        //             )
        //     )
        // );

        const dataset = datasets.find(
            (d) =>
                d.dataSource.id === dataSourceId &&
                isEqual(d.isVisible, true) &&
                isEqual(d.isSelected, true) &&
                isFiltered
        );

        const unselectedDatasets = pipe(
            datasets,
            A.filter(
                (d) =>
                    isEqual(d.isVisible, false) &&
                    isEqual(d.isSelected, false) &&
                    !multiFilters.find((m) => m.databaseId === d.dataSource.id)
            )
        );

        if (data && !isEmpty(unselectedDatasets)) {
            pipe(
                unselectedDatasets,
                A.map((d) =>
                    handleDatasetLayers({
                        action: "remove",
                        map,
                        isLoaded,
                        prefix: d.dataSource.id,
                        dispatch,
                    })
                )
            );
        }

        if (data && dataset) {
            const sources = getDatasetSources({
                prefix: dataSourceId,
                map,
                isLoaded,
                levelSets: data.levelSets,
            });

            const {
                aggregates,
                points,
                polygons,
                multiPolygons,
                lines,
                levelSets,
            } = getGeometryFromData({ dataSourceId, data });

            handleDatasetLayers({
                action: "add",
                map,
                isLoaded,
                prefix: dataSourceId,
                levelSets: data.levelSets,
                sources,
                overlap: isEmpty(filters),
                hierarchy: pipe(
                    datasets,
                    A.filter(
                        (d) =>
                            isEqual(d.isVisible, true) &&
                            isEqual(d.isSelected, true)
                    ),
                    A.map((d) => d.dataSource.id)
                ),
                data: {
                    isPreview: dataset.dataSource.isPreview || undefined,
                    isMipmapped: dataset.dataSource.isMipmapped || undefined,
                    type: dataset.dataSource.type,
                    showLevelSets: dataset.configuration.showLevelSets,
                    showPoints: dataset.configuration.showPoints,
                },
                sourcesData: {
                    aggregate: pipe(aggregates, A.concat(points)),
                    points,
                    polygons: pipe(
                        polygons,
                        A.concat(multiPolygons),
                        A.concat(lines)
                    ),
                    levelSets,
                },
                styles: getDatasetStyles(dataset),
                dispatch,
            });
        }
    };

    // biome-ignore lint/correctness/useExhaustiveDependencies: no additional dependency needed
    useEffect(() => {
        if (map.current) {
            // This is because the datasets from a SV are set after the map is loaded.
            if (isSavedViewPage) {
                setSelectedRowPulsingDot({
                    action: "add",
                    datasets: datasets,
                    map,
                    isLoaded: true,
                });
            } else {
                map.current.on("load", () => {
                    setSelectedRowPulsingDot({
                        action: "add",
                        datasets: datasets,
                        map,
                        isLoaded: true,
                    });
                });
            }
        }
    }, [map.current, datasets, selectedRows.length]);

    // biome-ignore lint/correctness/useExhaustiveDependencies: no additional dependency needed
    useEffect(() => {
        const isLoading =
            numberOfSelectedDatasets > 0 &&
            (renderingTimes.length !== numberOfSelectedDatasets ||
                !allRenderingTimesGreaterThanZero);

        mapDispatch?.({
            type: "SET_DATA_LOADING",
            values: { state: isLoading },
        });
    }, [
        numberOfSelectedDatasets,
        renderingTimes.length,
        allRenderingTimesGreaterThanZero,
    ]);

    // biome-ignore lint/correctness/useExhaustiveDependencies: no additional dependency needed
    useEffect(() => {
        if (recentResponse && map.current) {
            if (recentResponse.geometry?._tag !== "error") {
                setDatasetsFromResponse({
                    response: recentResponse,
                    map: map.current,
                    isLoaded,
                });

                const datasetId = recentResponse.databaseId;

                if (!isEmpty(filters)) {
                    // Hide the datasets that are currently getting filtered.
                    const filteredDatasets = pipe(
                        datasets,
                        A.filter(
                            (d) =>
                                isEqual(d.isSelected, true) &&
                                isEqual(d.isVisible, true) &&
                                !isEmpty(d.filters.filters)
                        )
                    );

                    updateDatasetsVisibility({
                        visibility: "none",
                        map,
                        isLoaded,
                        datasets: filteredDatasets,
                    });
                }

                if (!isRenderingTimePositive(datasetId)) {
                    const start = performance.now();
                    map.current.once("render", () => {
                        const end = performance.now();
                        dispatch(
                            commonActions.setRenderingTime({
                                datasetId,
                                layerRenderingTime: end - start,
                            })
                        );
                    });
                }
            }
        }
    }, [
        map.current,
        recentResponse?.uniqueId,
        style,
        datasets,
        filters.length,
    ]);

    return {
        onStyleLoad,
    };
};
