import {
    DataSource,
    InputDownloadStatisticsTable,
    MapStyleType,
    MarketplaceDataset,
    useCreatePreviewDataSourceFromMarketplaceDatasetMutation,
    useDownloadStatisticsParquetMutation,
    useFetchAllAreasExtendedQuery,
    useFetchUnPreviewedMarketplaceDatasetsQuery,
} from "@biggeo/bg-server-lib/datascape-ai";
import { Box, SelectableTreeMenuItem, Severity } from "@biggeo/bg-ui/lab";
import { MapLayout, MapLayoutTabs } from "@biggeo/bg-ui/lab/layouts";
import { toNonReadonlyArray } from "@biggeo/bg-utils";
import * as A from "fp-ts/lib/Array";
import * as E from "fp-ts/lib/Either";
import { Either } from "fp-ts/lib/Either";
import * as O from "fp-ts/lib/Option";
import { pipe } from "fp-ts/lib/function";
import isEmpty from "lodash/isEmpty";
import isEqual from "lodash/isEqual";
import isNil from "lodash/isNil";
import some from "lodash/some";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { SetURLSearchParams } from "react-router-dom";
import uuid from "react-uuid";
import tinycolor from "tinycolor2";
import { match as tsMatch } from "ts-pattern";

import OnMapLeave from "../../../common/components/OnMapLeave";
import { isAppRunningOnSF } from "../../../common/redux/hooks";
import { Consumers } from "../../../common/redux/model";
import { ExploreDatasetsViewContainer } from "../../../marketplace/containers/ExploreDatasetsViewContainer";
import MarketplaceDataViewContainer from "../../../marketplace/containers/MarketplaceDataViewContainer";
import MarketplaceDatasetPreviewContainer from "../../../marketplace/containers/MarketplaceDatasetPreviewContainer";
import { toasterActions } from "../../../toaster/containers/redux/model";
import { CallBacksType } from "../../../utils/types";
import { MapLayoutLeftContentContainer } from "../../containers/MapLayoutLeftContentContainer";
import DatasetTableSelectedRowsContainer from "../../filter-criteria/containers/DatasetTableSelectedRowsContainer";
import { MainDatasetTableContainer } from "../../filter-criteria/containers/MainDatasetTableContainer";
import MainFilteredDatasetTableContainer from "../../filter-criteria/containers/MainFilteredDatasetTableContainer";
import MapFilterCriteria from "../../filter-criteria/containers/MapFilterCriteria";
import { setFilterCriteria } from "../../filter-criteria/utils/utils";
import MapTopbar from "../../filter-criteria/views/MapTopbar";
import { usePureDataString } from "../../hooks/pure-data-string-hook";
import {
    MapTableTabs,
    MapTableTabsType,
    MapTabs,
} from "../../map-wrappers/MapViewWrapper";
import { isFeature } from "../../utils/draw-modes-utils";
import {
    FunctionType,
    SavedPolygonSource,
    convertSavedAreas,
    getMapFeatures,
    handleFilterReset,
    handleSelectedAreas,
    pickGeospatialSelection,
} from "../../utils/utils";
import MapShapeLayers from "../../views/MapShapeLayers";
import MapStyles, { DEFAULT_MAP_STYLE } from "../../views/MapStyles";
import { SavedAreasSubmenu } from "../../views/SavedAreasSubmenu";
import { useMap } from "../context";
import { setDatasetContext } from "../context/context-utils";
import {
    getMapTab,
    handleDatasetTable,
    handleSelectedSavedPolygons,
    resizeMap,
    selectSavedArea,
} from "../utils/map-utils";
import { MapboxMapContainer } from "./MapContainer";

export interface IMapMainContainer extends MapTableTabsType {
    readonly mainTab?: MapTabs;
    readonly mapTemplateId: number;
    readonly activeConsumption: Consumers;
    readonly setConsumption: (consumption: Consumers) => void;
    readonly handleSideMenu?: (v: boolean) => void;
    readonly setOpenSaveViewPopper: React.Dispatch<
        React.SetStateAction<boolean>
    >;
    readonly openSaveViewPopper: boolean;
    readonly selectedSavedView?: number;
    readonly isFromMarketplace?: boolean;
    readonly savedAreaId: number;
    readonly savedViewId?: number;
    readonly setSearchParams: SetURLSearchParams;
    readonly searchParams: URLSearchParams;
    readonly filterItems: SelectableTreeMenuItem[];
    readonly setFilterItems: React.Dispatch<
        React.SetStateAction<SelectableTreeMenuItem[]>
    >;
}

const MapMainContainer = ({
    mapTemplateId,
    isFromMarketplace = false,
    savedAreaId,
    savedViewId,
    searchParams,
    setSearchParams,
    filterItems,
    setFilterItems,
    tab,
    setTab,
    ...props
}: IMapMainContainer) => {
    const {
        responses,
        multiFilters,
        handleViewportChange,
        viewport,
        polygons,
        channelId,
        recentResponse,
        savedPolygons,
        setSavedPolygons,
        functionType,
        setFunctionType,
        clearShapes,
        handleMultiPolygons,
        deleteShape,
        handlePolygonsOnZoom,
        handleSavedPolygons,
        removeBoundaryById,
    } = usePureDataString();

    const {
        map,
        isLoaded,
        mapState,
        selectedShapes,
        dispatch,
        draw,
        modes,
        filters,
        datasets,
    } = useMap();

    const {
        addDatasets,
        updateDataset,
        removeDataset,
        overrideDatasets,
        reorderDatasets,
    } = setDatasetContext();

    const isRunningOnSF = isAppRunningOnSF();
    const isSavedViewPage = !isNil(savedViewId) && !isEqual(savedViewId, 0);

    const [navTab, setNavTab] = useState<MapLayoutTabs>(
        getMapTab(searchParams.get("mapTab"))
    );

    const [reFetchSavedAreas, setReFetchSavedAreas] = useState<boolean>(false);
    const [mapStyle, setMapStyle] = useState<MapStyleType>(
        DEFAULT_MAP_STYLE.key
    );
    const [selectedAreaId, setSelectedAreaId] = useState<number | undefined>();
    const [openBottomSlot, setOpenBottomSlot] = useState(false);
    const [previewDataset, setPreviewDataset] = useState<
        | Either<{ marketplaceDatasetId: string }, { dataSourceId: string }>
        | undefined
    >(undefined);
    const [refetchDatasourcePreview, setRefetchDatasourcePreview] =
        useState<boolean>(true);

    const [isPreviewBtnDisabled, setIsPreviewBtnDisabled] =
        useState<boolean>(false);

    const drawnPolygons = getMapFeatures(draw, isLoaded);

    const reduxDispatch = useDispatch();

    const handleSelectedShapes = (i: {
        type: "set" | "update";
        values:
            | GeoJSON.FeatureCollection<
                  GeoJSON.Geometry,
                  GeoJSON.GeoJsonProperties
              >
            | GeoJSON.Feature<GeoJSON.Geometry, GeoJSON.GeoJsonProperties>;
    }) => {
        tsMatch(i.type)
            .with("set", () =>
                dispatch?.({
                    type: "SET_SELECTED_SHAPES",
                    values: i.values,
                })
            )
            .with("update", () => {
                if (isFeature(i.values)) {
                    dispatch?.({
                        type: "UPDATE_SELECTED_SHAPE",
                        values: i.values,
                    });
                }
            })
            .exhaustive();
    };

    const handleMapTableTab = (t: MapTableTabs) => {
        setTab(t);

        handleDatasetTable({
            state:
                isEqual(t, MapTableTabs.split) ||
                isEqual(t, MapTableTabs.table),
            datasets,
            updateDataset,
        });
    };

    const {
        canOpenFilterCriteria,
        canAddFilterCriteria,
        viewedFilter,
        updateFilter,
        toggleFilterVisibility,
        removeFilter,
        clearFilters,
        editFilter,
        onAddFilter,
        handleFilterCriteria,
        saveFilter,
        addFilter,
        updatePreviewFilter,
        previewedFilter,
    } = setFilterCriteria({
        dispatch,
        selectedDatasets: pipe(
            datasets,
            A.filter((d) => isEqual(d.isSelected, true))
        ),
        filters,
        map,
        isLoaded,
        setPreviewDataset,
    });

    const isTopMapBarOpen =
        canAddFilterCriteria ||
        !isEmpty(drawnPolygons) ||
        !isEmpty(mapState?.selectedSavedAreas.savedAreas);

    const datasetsViewed = useMemo(
        () =>
            pipe(
                datasets,
                A.filter((d) => isEqual(d.isTableViewed, true))
            ),
        [datasets]
    );

    const isDatasetTableOpen =
        !isEmpty(datasetsViewed) && isEqual(tab, MapTableTabs.split);

    const isSavedAreaSelected = some(
        filterItems,
        (i) => !!i.selected || some(i.subItems, (s) => !!s.selected)
    );

    const resetAreasFilters = () =>
        setFilterItems(handleFilterReset(filterItems));

    const { executeMutation: downloadStatistics } =
        useDownloadStatisticsParquetMutation();

    const {
        queryReturn: { data: savedAreasData, refetch: refetchSavedAreas },
    } = useFetchAllAreasExtendedQuery(
        navTab === "boundaries" ||
            getMapTab(searchParams.get("mapTab")) === "boundaries" ||
            isSavedViewPage
            ? {
                  variables: {
                      fkMapTemplateId: mapTemplateId,
                  },
              }
            : { skip: true }
    );

    const exitMapModes = (tab: MapLayoutTabs) => {
        if (tab !== "datasets") {
            setRefetchDatasourcePreview(true);
        }
        if (tab === "shapeLayers") {
            setFunctionType(FunctionType.viewport);
            draw.current?.changeMode("simple_select");

            if (modes && isEqual(modes.select.isSelectMode, true)) {
                modes.select.selectMode(false);
                draw.current?.add({
                    type: "FeatureCollection",
                    features: pipe(
                        getMapFeatures(draw, isLoaded),
                        A.map((f) => ({
                            ...f,
                            properties: {
                                ...f.properties,
                                selected: false,
                            },
                        }))
                    ),
                });
            }
        }
        if (tab !== "shapeLayers") {
            reduxDispatch(
                toasterActions.openMapPopup({
                    sx: { display: "none" },
                })
            );
        }
    };

    const exploreDataset = () => {
        setOpenBottomSlot(true);
        pipe(
            datasets,
            A.map((dataset) =>
                updateDataset({
                    dataSourceId: dataset.dataSource.id,
                    dataset: {
                        isTableViewed: false,
                    },
                })
            )
        );
    };
    const onClickPreviewInfo = (
        input?: Either<
            { marketplaceDatasetId: string },
            { dataSourceId: string }
        >
    ) => {
        setPreviewDataset(input);

        if (input && E.isRight(input)) {
            updateDataset({
                dataSourceId: input.right.dataSourceId,
                dataset: {
                    isSelected: true,
                    isVisible: true,
                },
            });
        }

        if (previewedFilter) {
            removeFilter(previewedFilter.id);
        }
    };

    const {
        queryReturn: {
            loading: mpDatasetsLoading,
            data: mpDatasetsData,
            refetch: refetchUnPreviewedMarketplaceDatasets,
        },
    } = useFetchUnPreviewedMarketplaceDatasetsQuery(
        isRunningOnSF
            ? {
                  variables: {
                      input: {},
                  },
              }
            : { skip: true }
    );

    const { executeMutation: createDatasourceMutation } =
        useCreatePreviewDataSourceFromMarketplaceDatasetMutation();

    const onClickPreviewStatistics = (
        input: InputDownloadStatisticsTable,
        onSuccess: () => void
    ) => {
        downloadStatistics({
            variables: {
                input,
            },
            onError: (err) => {
                reduxDispatch(
                    toasterActions.openToast({
                        open: true,
                        title: `Statistics Download failed: ${err.message}`,
                        autoHideDuration: 5000,
                        severity: Severity.error,
                    })
                );
            },
            onCompleted: () => {
                refetchUnPreviewedMarketplaceDatasets();
                setRefetchDatasourcePreview(true);
                onSuccess();
            },
        });
    };

    const onClickPreviewDataset = (
        marketplaceDataset: MarketplaceDataset,
        callbacks?: CallBacksType<DataSource>
    ) => {
        setIsPreviewBtnDisabled(true);
        const randomColor = tinycolor.random();
        const color = randomColor.toHexString();

        createDatasourceMutation({
            variables: {
                input: {
                    id: uuid(),
                    fkMarketplaceDatasetId: marketplaceDataset.id,
                    size: marketplaceDataset.size,
                    isMipmapped: false,
                    tableName: marketplaceDataset.tableName,
                    collectionName: marketplaceDataset.collectionName,
                    geographyColumn: marketplaceDataset.geographyColumn,
                    description: marketplaceDataset.description,
                    label: marketplaceDataset.name,
                    color,
                    compute: false,
                    sfAlias: "",
                    isLoading: false,
                    type: marketplaceDataset.type,
                    tableId: marketplaceDataset.tableId,
                    isConnected: false,
                },
            },
            onError: (err) => {
                reduxDispatch(
                    toasterActions.openToast({
                        open: true,
                        title: "Dataset preview failed",
                        autoHideDuration: 5000,
                        severity: Severity.error,
                    })
                );
                callbacks?.onError?.(err.message);
            },
            onCompleted: (data) => {
                const dataSource =
                    data.createPreviewDataSourceFromMarketplaceDataset;

                setIsPreviewBtnDisabled(false);

                onClickPreviewStatistics(
                    {
                        dataSourceId: dataSource.id,
                        marketplaceDatasetId: marketplaceDataset.id,
                        path: marketplaceDataset.path,
                        collectionName: marketplaceDataset.collectionName,
                        tableName: marketplaceDataset.tableName,
                    },
                    () => callbacks?.onSuccess?.(dataSource)
                );
            },
        });
    };

    // biome-ignore lint/correctness/useExhaustiveDependencies: no additional dependency needed
    useEffect(() => {
        if (reFetchSavedAreas) {
            refetchSavedAreas?.();
            setReFetchSavedAreas?.(false);
        }
    }, [reFetchSavedAreas]);

    // biome-ignore lint/correctness/useExhaustiveDependencies: no additional dependency needed
    useEffect(() => {
        if (
            !isSavedAreaSelected &&
            !isEmpty(savedPolygons.polygons) &&
            !isSavedViewPage
        ) {
            setFunctionType(FunctionType.viewport);
            clearShapes();
            setSavedPolygons({
                source: SavedPolygonSource.savedArea,
                polygons: [],
                isConflict: false,
            });
        }
    }, [isSavedAreaSelected, filterItems, savedAreasData, isSavedViewPage]);

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        const canSetSavedAreaItems =
            !mapState ||
            mapState?.selectedSavedAreas.savedAreas.length === 0 ||
            !isEqual(savedAreaId, 0);

        if (canSetSavedAreaItems) {
            refetchSavedAreas();

            const data = convertSavedAreas(
                savedAreasData?.fetchAllAreasExtended || [],
                selectedAreaId
            );

            if (savedAreaId) {
                setTimeout(() => {
                    selectSavedArea({
                        items: handleSelectedAreas({
                            ids: [savedAreaId],
                            type: "subItem",
                            items: data,
                        }),
                        setFilterItems,
                        setFunctionType,
                        setSavedPolygons,
                        data: savedAreasData?.fetchAllAreasExtended || [],
                        drawnPolygons,
                        polygons,
                        deleteShape,
                    });
                }, 250);
            }

            setFilterItems(!isEmpty(data) ? data : []);
        }
    }, [
        savedAreasData,
        selectedAreaId,
        reFetchSavedAreas,
        savedAreaId,
        isSavedViewPage,
    ]);

    return (
        <OnMapLeave>
            <MapLayout
                sidebarOpen
                leftSubPanelOpen={false}
                bottomSize={
                    !!viewedFilter || isDatasetTableOpen ? 500 : undefined
                }
                rightOpen={canOpenFilterCriteria || !!previewDataset}
                topOpen={isTopMapBarOpen}
                bottomOpen={
                    !!viewedFilter || openBottomSlot || isDatasetTableOpen
                }
                mapLayoutTab={navTab}
                right={
                    canOpenFilterCriteria ? (
                        <MapFilterCriteria
                            map={map}
                            isLoaded={isLoaded}
                            isOpen={canOpenFilterCriteria}
                            mapTemplateId={mapTemplateId}
                            handleSidebar={handleFilterCriteria}
                            addFilter={addFilter}
                            updateFilter={updateFilter}
                            saveFilter={saveFilter}
                            currentFilter={
                                previewedFilter ||
                                pipe(
                                    filters,
                                    A.findFirst((f) =>
                                        isEqual(f.selected, true)
                                    ),
                                    O.fold(
                                        () => undefined,
                                        (f) => f
                                    )
                                )
                            }
                            resizeMap={() => resizeMap({ map, isLoaded })}
                            selectedDatasets={pipe(
                                datasets,
                                A.filter((d) => isEqual(d.isSelected, true))
                            )}
                            updatePreviewFilter={updatePreviewFilter}
                        />
                    ) : previewDataset ? (
                        <MarketplaceDatasetPreviewContainer
                            previewDataset={previewDataset}
                            onClickPreviewDataset={onClickPreviewDataset}
                            onClickPreviewInfo={onClickPreviewInfo}
                            isPreviewBtnDisabled={isPreviewBtnDisabled}
                            filters={{
                                polygon: viewport,
                                multipolygon: polygons.map((c) => ({
                                    inners: c.inners,
                                    outer: c.outer,
                                })),
                            }}
                        />
                    ) : undefined
                }
                top={
                    <MapTopbar
                        currentTab={tab}
                        datasets={datasets}
                        updateDataset={updateDataset}
                        setTab={handleMapTableTab}
                        onAddFilter={() => onAddFilter()}
                        clearFilters={() => clearFilters(map, isLoaded)}
                        removeFilter={(id) => removeFilter(id)}
                        filters={filters}
                        editFilter={editFilter}
                        viewFilterTable={(id, isViewed) => {
                            updateFilter({ id, viewed: !isViewed });
                        }}
                        responses={responses}
                        recentResponse={recentResponse}
                        viewport={viewport}
                        multipolygon={polygons}
                        multiFilters={multiFilters}
                        toggleFilterVisibility={(id, visible) =>
                            toggleFilterVisibility(id, visible)
                        }
                        deleteShape={deleteShape}
                        unselectBoundaries={(ids) => {
                            handleSelectedSavedPolygons({
                                setFilterItems,
                                setSavedPolygons,
                                drawnPolygons,
                                map,
                                savedAreas: [],
                                selectableItems: pipe(
                                    filterItems,
                                    toNonReadonlyArray,
                                    A.map((item) => ({
                                        ...item,
                                        selected: false,
                                        subItems: item.subItems
                                            ? pipe(
                                                  item.subItems,
                                                  toNonReadonlyArray,
                                                  A.map((subItem) => ({
                                                      ...subItem,
                                                      selected: false,
                                                  }))
                                              )
                                            : item.subItems,
                                    }))
                                ),
                            });

                            ids.map(removeBoundaryById);

                            setFunctionType(FunctionType.viewport);
                        }}
                        setNavTab={setNavTab}
                    />
                }
                bottom={
                    viewedFilter ? (
                        <MainFilteredDatasetTableContainer
                            tab={tab}
                            filter={viewedFilter}
                            handleClose={(id) => {
                                resizeMap({ map, isLoaded });
                                updateFilter({ id, viewed: undefined });
                            }}
                            channelId={channelId}
                            geospatialSelection={pickGeospatialSelection(
                                functionType,
                                {
                                    viewport,
                                    multipolygon: polygons.map((c) => ({
                                        inners: c.inners,
                                        outer: c.outer,
                                    })),
                                }
                            )}
                            isRunningOnSF={isRunningOnSF}
                        />
                    ) : isDatasetTableOpen ? (
                        <MainDatasetTableContainer
                            tab={tab}
                            datasets={datasetsViewed}
                            channelId={channelId}
                            updateDataset={updateDataset}
                            geospatialSelection={pickGeospatialSelection(
                                functionType,
                                {
                                    viewport,
                                    multipolygon: polygons.map((c) => ({
                                        inners: c.inners,
                                        outer: c.outer,
                                    })),
                                }
                            )}
                            isRunningOnSF={isRunningOnSF}
                        />
                    ) : openBottomSlot ? (
                        <MarketplaceDataViewContainer
                            onClose={() => {
                                setOpenBottomSlot(false);
                                resizeMap({ map, isLoaded });
                            }}
                            onClickPreviewDataset={onClickPreviewDataset}
                            mpDatasetsData={mpDatasetsData}
                            mpDatasetsLoading={mpDatasetsLoading}
                            onClickPreviewInfo={onClickPreviewInfo}
                            refetchUnPreviewedMarketplaceDatasets={
                                refetchUnPreviewedMarketplaceDatasets
                            }
                            isRunningOnSF={isRunningOnSF}
                        />
                    ) : undefined
                }
                leftSubPanel={
                    <ExploreDatasetsViewContainer
                        exploreDataset={exploreDataset}
                        onClickPreviewInfo={onClickPreviewInfo}
                        refetchDatasourcePreview={refetchDatasourcePreview}
                        setRefetchDatasourcePreview={
                            setRefetchDatasourcePreview
                        }
                        updateDataset={updateDataset}
                        addDatasets={addDatasets}
                        isSavedViewPage={isSavedViewPage}
                        reorderDatasets={reorderDatasets}
                        refetchUnPreviewedMarketplaceDatasets={
                            refetchUnPreviewedMarketplaceDatasets
                        }
                    />
                }
                datasets={
                    <MapLayoutLeftContentContainer
                        map={map}
                        isLoaded={isLoaded}
                        mapTemplateId={mapTemplateId}
                        isFromMarketplace={isFromMarketplace}
                        addDatasets={addDatasets}
                        updateDataset={updateDataset}
                        removeDataset={removeDataset}
                        isSavedViewPage={isSavedViewPage}
                        overrideDatasets={overrideDatasets}
                        reorderDatasets={reorderDatasets}
                    />
                }
                shapeLayers={
                    <MapShapeLayers
                        deleteShape={deleteShape}
                        functionType={functionType}
                        exitMapModes={() => exitMapModes("shapeLayers")}
                    />
                }
                boundaries={
                    <SavedAreasSubmenu
                        filterItems={filterItems}
                        selectSavedArea={(items) =>
                            selectSavedArea({
                                items,
                                setFilterItems,
                                setFunctionType,
                                setSavedPolygons,
                                data:
                                    savedAreasData?.fetchAllAreasExtended || [],
                                deleteShape,
                                drawnPolygons,
                                polygons,
                            })
                        }
                    />
                }
                maps={
                    <MapStyles
                        isLoaded={isLoaded}
                        map={map}
                        currentStyle={
                            map.current && isLoaded
                                ? map.current.getStyle()
                                : undefined
                        }
                        onStyleChange={(style) => setMapStyle(style)}
                        draw={draw}
                        datasets={datasets}
                    />
                }
                onSidebarOpenChange={(_, tab) => {
                    resizeMap({ map, isLoaded });
                    exitMapModes(tab);
                    setNavTab(tab);
                }}
                onSidebarIconClick={(_, tab) => {
                    exitMapModes(tab);
                    setNavTab(tab);
                }}
                onSidebarResizeEnd={() => resizeMap({ map, isLoaded })}
                onTopOpenChange={() => resizeMap({ map, isLoaded })}
                onTopResizeEnd={() => resizeMap({ map, isLoaded })}
                onRightOpenChange={() => resizeMap({ map, isLoaded })}
                onRightResizeEnd={() => resizeMap({ map, isLoaded })}
                onBottomOpenChange={() => resizeMap({ map, isLoaded })}
                onBottomResizeEnd={() => resizeMap({ map, isLoaded })}
            >
                <Box
                    height={"100%"}
                    width={"100%"}
                    sx={{
                        padding: isEqual(tab, MapTableTabs.table)
                            ? undefined
                            : 4,
                        overflow: "auto",
                        borderRadius: (theme) => theme.radius.xs3,
                    }}
                >
                    {tsMatch(tab)
                        .with(MapTableTabs.table, () => (
                            <MainDatasetTableContainer
                                tab={tab}
                                datasets={datasetsViewed}
                                channelId={channelId}
                                updateDataset={updateDataset}
                                geospatialSelection={pickGeospatialSelection(
                                    functionType,
                                    {
                                        viewport,
                                        multipolygon: polygons.map((c) => ({
                                            inners: c.inners,
                                            outer: c.outer,
                                        })),
                                    }
                                )}
                                isRunningOnSF={isRunningOnSF}
                            />
                        ))
                        .otherwise(() => (
                            <MapboxMapContainer
                                key={Consumers.mapbox}
                                {...props}
                                deleteShape={deleteShape}
                                handleSavedPolygons={handleSavedPolygons}
                                handlePolygonsOnZoom={handlePolygonsOnZoom}
                                isFilterCriteriaOpen={canOpenFilterCriteria}
                                filters={filters}
                                functionType={functionType}
                                multiFilters={multiFilters}
                                recentResponse={recentResponse}
                                responses={responses}
                                viewport={viewport}
                                handleViewportChange={handleViewportChange}
                                savedPolygons={savedPolygons}
                                channelId={channelId}
                                setFunctionType={setFunctionType}
                                setSavedPolygons={setSavedPolygons}
                                polygons={polygons}
                                handleMultiPolygons={handleMultiPolygons}
                                clearShapes={clearShapes}
                                setSelectedAreaId={setSelectedAreaId}
                                setReFetchSavedAreas={setReFetchSavedAreas}
                                resetAreasFilters={resetAreasFilters}
                                handleSelectedSavedPolygons={(i) =>
                                    handleSelectedSavedPolygons({
                                        ...i,
                                        setFilterItems,
                                        setSavedPolygons,
                                        drawnPolygons,
                                        map,
                                    })
                                }
                                selectableItems={pipe(
                                    filterItems,
                                    toNonReadonlyArray
                                )}
                                selectedShapes={selectedShapes}
                                handleSelectedShapes={handleSelectedShapes}
                                savedViewId={savedViewId}
                                setSearchParams={setSearchParams}
                                mapTemplateId={mapTemplateId}
                                mapStyle={mapStyle}
                                addDatasets={addDatasets}
                                updateDataset={updateDataset}
                                removeDataset={removeDataset}
                                reorderDatasets={reorderDatasets}
                                clearFilters={() => clearFilters(map, isLoaded)}
                            />
                        ))}
                    <DatasetTableSelectedRowsContainer />
                </Box>
            </MapLayout>
        </OnMapLeave>
    );
};

export default MapMainContainer;
