import { User } from "@biggeo/bg-server-lib/datascape-ai";
import { Organization } from "@biggeo/bg-server-lib/studio";
import {
    AvatarChip,
    AvatarGroup,
    Box,
    Button,
    CompanyAvatar,
    Divider,
    Grid,
    Menu,
    OverflowingTypography,
    Stack,
    UserAvatar,
    typographyClasses,
} from "@biggeo/bg-ui/lab";
import { ExpandMoreOutline, LogoutOutline } from "@biggeo/bg-ui/lab/icons";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { compose } from "redux";

import { Routes, navigationActions } from "../navigation/redux/model";
import { useLogout } from "../user/redux/hooks";

interface IAccountMenu {
    readonly user: User;
    readonly organization?: Organization;
}

export const AccountMenu = ({ user, organization }: IAccountMenu) => {
    const [open, setOpen] = useState(false);
    const logout = useLogout();

    const dispatch = useDispatch();
    const toPage = compose(dispatch, navigationActions.toRoute);

    return (
        <Menu
            open={open}
            onOpenChange={(open) => setOpen(open)}
            placement="bottom-end"
            sx={{
                padding: 0,
                //@UiDiscrepancy - Figma calls for 67.5 but bumped up to 72 to ensure the default text like "create new organization" does not truncate in the components base state
                maxWidth: 72,
                width: "100%",
                gap: 0,
                scrollbarGutter: "auto",
            }}
            content={
                <>
                    <Grid
                        container
                        alignItems="center"
                        gap={2}
                        sx={{ padding: 4 }}
                    >
                        <UserAvatar
                            alt={`${user.firstName} ${user.lastName}`}
                            src={user.img || undefined}
                            size="xs"
                        />
                        <Grid item xs minWidth={0}>
                            <Stack>
                                <OverflowingTypography
                                    variant="body3"
                                    fontWeight="semibold"
                                >{`${user.firstName} ${user.lastName}`}</OverflowingTypography>
                                <OverflowingTypography variant="body4">
                                    {user.email}
                                </OverflowingTypography>
                            </Stack>
                        </Grid>
                    </Grid>
                    <Divider />
                    <Box
                        sx={{
                            padding: 2,
                        }}
                    >
                        <Button
                            variant="outlined"
                            fullWidth
                            density="dense"
                            endNode={<LogoutOutline />}
                            onClick={() => {
                                logout();
                                toPage(`${Routes.signIn}`);
                            }}
                        >
                            Log Out
                        </Button>
                    </Box>
                </>
            }
        >
            <AvatarChip
                density="dense"
                startNode={
                    <AvatarGroup size="xs">
                        <UserAvatar
                            alt={`${user.firstName} ${user.lastName}`}
                            src={user.img || ""}
                        />
                        <CompanyAvatar
                            alt={organization?.name}
                            src={organization?.img || ""}
                        />
                    </AvatarGroup>
                }
                endNode={
                    <ExpandMoreOutline
                        sx={{
                            transform: open ? "rotate(180deg)" : "rotate(0deg)",
                            transition: "transform 0.1s ease-in-out",
                        }}
                    />
                }
                sx={{
                    maxWidth: 40,

                    breakpoints: {
                        xs: {
                            [`& .${typographyClasses.root}`]: {
                                display: "none",
                            },
                        },
                        md: {
                            [`& .${typographyClasses.root}`]: {
                                display: "block",
                            },
                        },
                    },
                }}
            >{`${user.firstName} ${user.lastName}`}</AvatarChip>
        </Menu>
    );
};
