import { combineReducers } from "redux";
import { commonReducer } from "../common/redux/model";
import { dataSourcesReducer } from "../database-meta-data/redux/model";
import { aiReducer } from "../map/ai/redux/model";
import { mapTooltipReducer } from "../map/map-tooltip/redux/model";
import { mapDataReducer } from "../map/redux/model";
import { modalReducer } from "../modal/redux/model";
import { toasterReducer } from "../toaster/containers/redux/model";
import { usersReducer } from "../user/redux/model";

const createRootReducer = () =>
    combineReducers({
        dataSources: dataSourcesReducer,
        toaster: toasterReducer,
        mapTooltip: mapTooltipReducer,
        modal: modalReducer,
        mapData: mapDataReducer,
        ai: aiReducer,
        common: commonReducer,
        user: usersReducer,
    });

export default createRootReducer;
export type RootState = ReturnType<ReturnType<typeof createRootReducer>>;
