import { IconButton, Stack } from "@biggeo/bg-ui/lab";
import { CloseOutline } from "@biggeo/bg-ui/lab/icons";
import { startsWith } from "lodash";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router";
import { compose } from "redux";
import { useClosableCallToActions } from "../../common/redux/hooks";
import { commonActions } from "../../common/redux/model";
import { Routes } from "../../navigation/redux/model";
import { UnlockDatasetViaAccessKeyForm } from "../forms/UnlockDatasetViaAccessKeyForm";

export const DataAccessKeyFormPanel = () => {
    const path = useLocation().pathname;
    const dispatch = useDispatch();
    const open = useClosableCallToActions("dataAccessKeyFormPanel");

    const showOnRoutes = [Routes.home, Routes.explore];
    const show =
        path === Routes.home ||
        showOnRoutes.some(
            (route) => route !== Routes.home && startsWith(path, route)
        );

    const handleClose = () =>
        compose(
            dispatch,
            commonActions.closeCallToAction
        )("dataAccessKeyFormPanel");

    return show ? (
        <Stack
            gap={4}
            sx={{
                display: open ? "flex" : "none",
                width: (theme) => `calc(100% - ${theme.spacing(8)})`,
                maxHeight: (theme) => `calc(100% - ${theme.spacing(8)})`,
                background: (theme) => theme.palette.background.container,
                color: (theme) => theme.palette.background.onContainer,
                maxWidth: 97,
                position: "fixed",
                bottom: 4,
                left: 4,
                borderRadius: (theme) => theme.radius.xs3,
                zIndex: (theme) => theme.zIndex.drawer - 5,
                backgroundColor: (theme) => theme.palette.background.main,
                boxShadow: (theme) => theme.shadows.allAround,
                overflow: "auto",
            }}
        >
            <IconButton
                id={"close_unlock_dataset_via_access_key_form_popup"}
                variant="minimal"
                onClick={handleClose}
                sx={{ alignSelf: "end", marginTop: 4, marginRight: 4 }}
            >
                <CloseOutline />
            </IconButton>
            <UnlockDatasetViaAccessKeyForm handleClose={handleClose} />
        </Stack>
    ) : null;
};
