import {
    FilterObject,
    InputViewBox,
    SubscriptionResponse,
} from "@biggeo/bg-server-lib/datascape-ai";
import { Button } from "@biggeo/bg-ui/lab";
import { DownloadOutline } from "@biggeo/bg-ui/lab/icons";
import { isEmpty } from "lodash";
import React, { useState } from "react";
import { v4 as uuidV4 } from "uuid";
import { useErrorToast } from "../../toaster/containers/redux/hooks";
import { InputPolygonWithId } from "../hooks/pure-data-string-hook";
import { rbgUrl } from "../utils/subscription";
import { sumCounts } from "../utils/utils";

interface IExportContainer {
    readonly viewport?: InputViewBox;
    readonly multipolygon?: readonly InputPolygonWithId[];
    readonly multiFilters: FilterObject[];
    readonly recentResponse?: SubscriptionResponse;
    readonly responses: Partial<Record<string, SubscriptionResponse | null>>;
}

const ExportContainer = ({
    multiFilters,
    multipolygon,
    viewport,
    recentResponse,
    responses,
}: IExportContainer) => {
    const errorToast = useErrorToast();

    const filtered = Object.keys(responses).filter((c) => {
        const response = responses[c];
        const sumOfAllPoints = response?.geometry?.nonPoints.length
            ? recentResponse?.geometry?.nonPoints.length || 0
            : recentResponse?.geometry?.pointCount.low || 0;
        const sumOfAllAggregatedPoints =
            sumOfAllPoints +
            (response?.geometry?.nonPointCount
                ? response?.geometry?.nonPointCount.low
                : response?.geometry?.aggregation
                  ? sumCounts(response.geometry.aggregation)
                  : 0);

        return sumOfAllAggregatedPoints <= 100000;
    });

    const [isLoading, setIsLoading] = useState(false);

    const handleExport = async () => {
        setIsLoading(true);
        try {
            const response = await fetch(`${rbgUrl}/export_data`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    multiFilters: multiFilters.filter((c) =>
                        filtered.includes(c.databaseId)
                    ),
                    requestId: uuidV4(),
                    channel: uuidV4(),
                    dateTime: Date.now().toString(),
                    multipolygon:
                        multipolygon && !isEmpty(multipolygon)
                            ? {
                                  polygons: multipolygon.map((p) => ({
                                      inners: p.inners,
                                      outer: p.outer,
                                  })),
                              }
                            : undefined,
                    viewport: !isEmpty(multipolygon) ? undefined : viewport,
                }),
            });

            if (!response.ok) {
                throw new Error("Network response was not ok");
            }

            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "output.zip"); // Ensure this matches the backend response
            document.body.appendChild(link);
            link.click();
            link.remove();
        } catch (error) {
            console.error("Export failed:", error);
            errorToast("Failed to export data. Please try again later.");
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Button
            onClick={handleExport}
            density="dense"
            variant="outlined"
            startNode={<DownloadOutline />}
            disabled={isLoading || filtered.length === 0}
        >
            {isLoading ? "Downloading..." : "Download Shapefiles"}
        </Button>
    );
};

export default ExportContainer;
