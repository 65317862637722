import { Stack } from "@biggeo/bg-ui/lab";
import { CropFreeOutline, LayersOutline } from "@biggeo/bg-ui/lab/icons";
import * as A from "fp-ts/lib/Array";
import * as O from "fp-ts/lib/Option";
import { pipe } from "fp-ts/lib/function";
import isEmpty from "lodash/isEmpty";
import isString from "lodash/isString";

import { FeedbackChip } from "../../../common/components/FeedbackChip";
import { useMap } from "../../mapbox/context";
import { useMapData } from "../../redux/hooks";
import { getSavedViewPolygons } from "../../utils/utils";
import { DataGridDivider } from "../components/DatasetTableHeader";
import { IMapTopbar } from "../views/MapTopbar";

const MapFeedbackContainer = ({
    deleteShape,
    unselectBoundaries,
    setNavTab,
}: Pick<IMapTopbar, "deleteShape" | "unselectBoundaries" | "setNavTab">) => {
    const { mapState } = useMap();
    const savedView = useMapData();

    const savedAreasLinkedToSV = pipe(
        savedView?.savedArea,
        O.fromNullable,
        O.fold(
            () => [],
            (savedArea) =>
                getSavedViewPolygons(savedArea.geometries)
                    .savedAreaGeometries || []
        )
    );

    const hasShapesApplied = !isEmpty(mapState?.featureCollection?.features);
    const hasBoundariesApplied =
        !isEmpty(mapState?.selectedSavedAreas.savedAreas) ||
        !isEmpty(savedAreasLinkedToSV);

    return (
        <Stack
            width="100%"
            height="100%"
            flexDirection="row"
            alignItems="center"
            gap={2}
            sx={{
                paddingLeft: 2,
            }}
        >
            {hasShapesApplied && (
                <Stack flexDirection="row" alignItems="center" gap={2}>
                    <DataGridDivider />
                    <FeedbackChip
                        startNode={<LayersOutline size="xs" />}
                        text="Shapes Applied"
                        onModalSaveClick={() =>
                            pipe(
                                mapState?.featureCollection?.features || [],
                                A.map((feature) => {
                                    if (isString(feature.id)) {
                                        deleteShape(feature.id);
                                    }
                                })
                            )
                        }
                        count={
                            mapState?.featureCollection?.features.length || 0
                        }
                        onClick={() => setNavTab("shapeLayers")}
                        subtitle="Proceeding will delete all shapes applied, are you sure you want to proceed?"
                        buttons={{
                            text1: "Keep Shapes",
                            text2: "Yes, Delete Shapes",
                        }}
                    />
                </Stack>
            )}
            {hasBoundariesApplied && (
                <Stack flexDirection="row" alignItems="center" gap={2}>
                    <DataGridDivider />
                    <FeedbackChip
                        startNode={<CropFreeOutline size="xs" />}
                        text="Boundaries Applied"
                        onModalSaveClick={() => {
                            if (mapState?.selectedSavedAreas.savedAreas) {
                                unselectBoundaries(
                                    mapState.selectedSavedAreas.savedAreas.map(
                                        (sa) => sa.id
                                    )
                                );
                            }
                        }}
                        count={
                            mapState?.selectedSavedAreas.savedAreas.length ||
                            savedAreasLinkedToSV.length ||
                            0
                        }
                        onClick={() => setNavTab("boundaries")}
                        subtitle="Proceeding will delete all boundaries applied, are you sure you want to proceed?"
                        buttons={{
                            text1: "Keep Boundaries",
                            text2: "Yes, Delete Boundaries",
                        }}
                    />
                </Stack>
            )}
        </Stack>
    );
};

export default MapFeedbackContainer;
