import { ApolloQueryResult } from "@apollo/client";
import {
    DataSourceExtended,
    Exact,
    FetchUnPreviewedMarketplaceDatasetsQuery,
    InputFetchUnPreviewedDatasets,
    InputUpdateDataSource,
    useFetchDataSourcesExtendedLazyQuery,
    useRemovePreviewDatasetMutation,
    useUpdateDataSourceMutation,
} from "@biggeo/bg-server-lib/datascape-ai";
import { LoadingBar, Severity } from "@biggeo/bg-ui/lab";
import { WithPartialValues } from "@biggeo/bg-utils";
import { match } from "@vividtheory/remotedata";
import * as A from "fp-ts/lib/Array";
import * as E from "fp-ts/lib/Either";
import * as O from "fp-ts/lib/Option";
import { pipe } from "fp-ts/lib/function";
import isEqual from "lodash/isEqual";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import ErrorPage from "../../common/components/ErrorPage";
import { isAppRunningOnSF } from "../../common/redux/hooks";
import { databaseMetaDataActions } from "../../database-meta-data/redux/model";
import { DEFAULT_LOGIC_OPERATOR } from "../../map/filter-criteria/utils/utils";
import { MapContextPreviewDataset, useMap } from "../../map/mapbox/context";
import {
    SetDatasetContextType,
    getDefaultDatasetConfiguration,
} from "../../map/mapbox/context/context-utils";
import { removePreviewDatasetLayer } from "../../map/mapbox/utils/preview-data-layers-utils";
import { toasterActions } from "../../toaster/containers/redux/model";
import { ExploreDatasetsView } from "../views/ExploreDatasetsView";

export const ExploreDatasetsViewContainer = ({
    exploreDataset,
    onClickPreviewInfo,
    refetchDatasourcePreview,
    setRefetchDatasourcePreview,
    updateDataset,
    addDatasets,
    isSavedViewPage,
    reorderDatasets,
    refetchUnPreviewedMarketplaceDatasets,
}: {
    readonly refetchUnPreviewedMarketplaceDatasets: (
        variables?:
            | Partial<
                  Exact<{
                      input: InputFetchUnPreviewedDatasets;
                  }>
              >
            | undefined
    ) => Promise<ApolloQueryResult<FetchUnPreviewedMarketplaceDatasetsQuery>>;

    readonly exploreDataset: () => void;
    readonly onClickPreviewInfo: (
        input?: E.Either<
            {
                marketplaceDatasetId: string;
            },
            {
                dataSourceId: string;
            }
        >
    ) => void;
    readonly setRefetchDatasourcePreview: (value: boolean) => void;
    readonly refetchDatasourcePreview: boolean;
    readonly isSavedViewPage: boolean;
} & Pick<
    SetDatasetContextType,
    "updateDataset" | "addDatasets" | "reorderDatasets"
>) => {
    const isRunningOnSF = isAppRunningOnSF();
    const rdxDispatch = useDispatch();
    const { datasets, map, isLoaded, dispatch, previews } = useMap();
    const { executeQuery, remote } = useFetchDataSourcesExtendedLazyQuery();
    const { executeMutation: removePreviewDataset } =
        useRemovePreviewDatasetMutation();
    const { executeMutation: updateDataSourceMutation } =
        useUpdateDataSourceMutation();

    const updatePreviewDatasets = (d: DataSourceExtended[]) => {
        rdxDispatch(databaseMetaDataActions.fetchPreviewDataSources.success(d));
    };

    const setPreviewDataset = (values: {
        dataSourceId: string;
        dataset: Partial<WithPartialValues<MapContextPreviewDataset>>;
    }) => {
        dispatch?.({
            type: "UPDATE_PREVIEW_DATASET",
            values,
        });
    };

    const deletePreviewDataset = (dataSourceId: string) => {
        dispatch?.({
            type: "REMOVE_PREVIEW_DATASET",
            values: { dataSourceId },
        });

        const preview = pipe(
            previews,
            A.findFirst((p) => p.dataSourceId === dataSourceId),
            O.fold(
                () => undefined,
                (p) => p
            )
        );

        if (map.current && preview) {
            removePreviewDatasetLayer({ map: map.current, isLoaded, preview });
        }
    };

    const onRemove = (id: string) => {
        return removePreviewDataset({
            variables: { id },
            onCompleted: () => {
                dispatch?.({
                    type: "REMOVE_DATASET",
                    values: { dataSourceId: id },
                });
                deletePreviewDataset(id);
                refetchUnPreviewedMarketplaceDatasets();
            },
        });
    };

    const onEdit = (dataSourceId: string) => {
        const preview = pipe(
            previews,
            A.findFirst((p) => p.dataSourceId === dataSourceId),
            O.fold(
                () => undefined,
                (p) => p
            )
        );

        if (preview) {
            dispatch?.({
                type: "UPDATE_PREVIEW_DATASET",
                values: {
                    dataSourceId,
                    dataset: {
                        isGettingStyled: !preview.isGettingStyled,
                    },
                },
            });
        }
    };

    const updateDataSource = (input: InputUpdateDataSource) => {
        updateDataSourceMutation({
            variables: {
                input,
            },
            onCompleted: (data) => {
                rdxDispatch(
                    toasterActions.openToast({
                        open: true,
                        title: "Preview Dataset Styles updated successfully.",
                        autoHideDuration: 5000,
                        severity: Severity.success,
                    })
                );

                updateDataset({
                    dataSourceId: data.updateDataSource.id,
                    dataset: {
                        dataSource: data.updateDataSource,
                    },
                });

                rdxDispatch(
                    databaseMetaDataActions.updatePreviewDataset({
                        id: data.updateDataSource.id,
                        heatmapColor: data.updateDataSource.heatmapColor,
                    })
                );
            },
        });
    };

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        if (refetchDatasourcePreview) {
            executeQuery({
                variables: {
                    input: {
                        isPreview: true,
                    },
                },
                onCompleted: (d) => {
                    if (!isSavedViewPage) {
                        const dataSources = d.fetchDataSourcesExtended;
                        updatePreviewDatasets(dataSources);

                        setRefetchDatasourcePreview(false);

                        datasets.map((dataset) => {
                            const found = d.fetchDataSourcesExtended.find(
                                (i) => i.dataSource.id === dataset.dataSource.id
                            );

                            if (found) {
                                updateDataset({
                                    dataSourceId: found.dataSource.id,
                                    dataset: {
                                        dataSource: found.dataSource,
                                        isVisible:
                                            found.dataSource.compute === true &&
                                            found.dataSource.isConnected ===
                                                true,
                                        isSelected:
                                            found.dataSource.compute === true &&
                                            found.dataSource.isConnected ===
                                                true,
                                        isGettingStyled: false,
                                        isLegendOpen: false,
                                        isTableViewed: false,
                                        configuration:
                                            getDefaultDatasetConfiguration(
                                                isRunningOnSF
                                            ),
                                        filters: {
                                            logicOperator:
                                                DEFAULT_LOGIC_OPERATOR,
                                            filters: [],
                                        },
                                        selectedRows: [],
                                        pinnedRows: [],
                                    },
                                });
                            }
                        });

                        d.fetchDataSourcesExtended.map(({ dataSource }) => {
                            addDatasets({
                                dataSource,
                                mapTemplateDataset: undefined,
                                isVisible:
                                    dataSource.compute === true &&
                                    dataSource.isConnected === true,
                                isSelected:
                                    dataSource.compute === true &&
                                    dataSource.isConnected === true,
                                isGettingStyled: false,
                                isLegendOpen: false,
                                isTableViewed: false,
                                configuration:
                                    getDefaultDatasetConfiguration(
                                        isRunningOnSF
                                    ),
                                filters: {
                                    logicOperator: DEFAULT_LOGIC_OPERATOR,
                                    filters: [],
                                },
                                selectedRows: [],
                                pinnedRows: [],
                            });
                        });
                    }
                },
            });
        }
    }, [refetchDatasourcePreview, isRunningOnSF]);

    return match(remote, {
        _: () => <LoadingBar />,
        Success: () => {
            return (
                <ExploreDatasetsView
                    datasets={pipe(
                        datasets,
                        A.filter((d) => isEqual(d.dataSource.isPreview, true))
                    )}
                    onClickPreviewInfo={onClickPreviewInfo}
                    exploreDataset={exploreDataset}
                    onRemove={onRemove}
                    onEdit={onEdit}
                    updateDataset={updateDataset}
                    reorderDatasets={reorderDatasets}
                    map={map}
                    isLoaded={isLoaded}
                    setPreviewDataset={setPreviewDataset}
                    updateDataSource={updateDataSource}
                />
            );
        },
        Failure: (err) => <ErrorPage subtitle={err.message} />,
    });
};
