import {
    ApolloLink,
    HttpLink,
    concat,
    gql,
    split,
    useSubscription,
} from "@apollo/client";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { getMainDefinition } from "@apollo/client/utilities";
import { Applications, getJwtToken, getUuid } from "@biggeo/bg-common";
import {
    SubscriptionResponse,
    SubscriptionStringResponse,
} from "@biggeo/bg-server-lib/datascape-ai";
import { Severity } from "@biggeo/bg-ui/lab";
import { createClient } from "graphql-ws";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import io from "socket.io-client";
import { commonActions } from "../../common/redux/model";
import { toasterActions } from "../../toaster/containers/redux/model";
import { useMap } from "../mapbox/context/map";
import {
    subscriptionStringToSubscriptionResponse,
    subscriptionStringToSubscriptionResponseNew,
} from "./utils";

const serverLocation = window.location.host;
const serverProtocol = window.location.protocol;
const websocketProtocol = serverProtocol === "https:" ? "wss:" : "ws:";

export const wsLink = new GraphQLWsLink(
    createClient({
        url: serverLocation.includes("local")
            ? "ws://localhost:5001/rbg/sub"
            : `${websocketProtocol}//${serverLocation}/rbg/sub`,
    })
);

export const nodeWsLink = new GraphQLWsLink(
    createClient({
        url: serverLocation.includes("local")
            ? "ws://localhost:5001/api/sub"
            : `${websocketProtocol}//${serverLocation}/api/sub`,
    })
);

export const httpLink = new HttpLink({
    uri: serverLocation.includes("local")
        ? "http://localhost:5001/api"
        : `${serverProtocol}//${serverLocation}/api`,
});

export const rustHttpLink = new HttpLink({
    uri: serverLocation.includes("local")
        ? "http://localhost:5001/rbg/api"
        : `${serverProtocol}//${serverLocation}/rbg/api`,
});

export const rbgUrl = serverLocation.includes("local")
    ? "http://localhost:5001/rbg"
    : `${serverProtocol}//${serverLocation}/rbg`;

export const rustLink = new HttpLink({
    uri: serverLocation.includes("local")
        ? "http://localhost:5001/rbg/api"
        : `${serverProtocol}//${serverLocation}/rbg/api`,
});

const authMiddleware = ({
    headers,
    app,
}: {
    headers: Record<string, string | undefined>;
    app: Applications;
}) =>
    new ApolloLink((operation, forward) => {
        const token = getJwtToken(app);
        const uuid = getUuid(app);

        // add the authorization to the headers

        operation.setContext({
            headers: {
                uuid,
                ...headers,
                ...(token ? { authorization: `Bearer ${token}` } : {}),
            },
        });
        return forward(operation);
    });

export const appHttpLink = new HttpLink({
    uri: serverLocation.includes("local")
        ? "http://localhost:5001"
        : `${serverProtocol}//${serverLocation}`,
});

const splitLink = split(
    ({ query }) => {
        const definition = getMainDefinition(query);
        return (
            definition.kind === "OperationDefinition" &&
            definition.operation === "subscription"
        );
    },
    split(
        (operation) => operation.getContext().clientName === "rust",
        wsLink,
        nodeWsLink
    ),
    split(
        (operation) => operation.getContext().clientName === "rust",
        rustHttpLink,
        httpLink
    )
);

export const getSplitLink = ({
    headers,
    app,
}: {
    readonly headers: Record<string, string | undefined>;
    readonly app: Applications;
}) => {
    return concat(authMiddleware({ headers, app }), splitLink);
};

const MAP_SUBSCRIPTION = gql`
  subscription listenToMapChanges($channel: String!) {
    listenToMapChanges(channel: $channel) {
      data
      geometry {
        aggregation {
          cellID
          count
          cellVertices {
            points {
              latitude
              longitude
            }
          }
          meanPoint {
            latitude
            longitude
          }
        }
        points {
          id
          point {
            latitude
            longitude
          }
          lineStrip {
            points {
              latitude
              longitude
            }
          }
          polygon {
            outer {
              points {
                latitude
                longitude
              }
            }
            inners {
              points {
                latitude
                longitude
              }
            }
          }
          multipolygon {
            polygons {
              outer {
                points {
                  latitude
                  longitude
                }
              }
              inners {
                points {
                  latitude
                  longitude
                }
              }
            }
          }
        }
        timeMs
        pointCount
        nonPointCount
        nonPoints {
          id
          point {
            latitude
            longitude
          }
          lineStrip {
            points {
              latitude
              longitude
            }
          }
          polygon {
            outer {
              points {
                latitude
                longitude
              }
            }
            inners {
              points {
                latitude
                longitude
              }
            }
          }
          multipolygon {
            polygons {
              outer {
                points {
                  latitude
                  longitude
                }
              }
              inners {
                points {
                  latitude
                  longitude
                }
              }
            }
          }
        }
      }
      dateTime
      uniqueId
      databaseId
    }
  }
`;

const MAP_SUBSCRIPTION_V2 = gql`
  subscription listenToMapChangesString($channel: String) {
    listenToMapChangesString(channel: $channel) {
      dateTime
      uniqueId
      databaseId
      timeMs
      data
      extraData
      requestId
      collectionExist
    }
  }
`;
const NEW_MAP_SUBSCRIPTION_V2 = gql`
  subscription listenToMapChangesStringNew($channel: String!) {
    listenToMapChangesStringNew(channel: $channel) {
      dateTime
      uniqueId
      databaseId
      timeMs
      data
      extraData
      collectionExist
    }
  }
`;

export const useLatestMapChanges = (
    channel: string,
    onDataChange: (data: SubscriptionResponse) => void
) => {
    const { data, loading } = useSubscription<
        {
            listenToMapChanges: SubscriptionResponse;
        },
        { channel: string }
    >(MAP_SUBSCRIPTION, {
        variables: {
            channel: channel,
        },
        fetchPolicy: "no-cache",
    });

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        if (data?.listenToMapChanges) {
            onDataChange(data?.listenToMapChanges);
        }
    }, [data?.listenToMapChanges?.uniqueId]);

    return { data, loading };
};

export const useLatestMapChangesV2 = (
    channel: string,
    onDataChange: (data: SubscriptionResponse) => void
) => {
    const { data } = useSubscription<
        {
            listenToMapChangesString: SubscriptionStringResponse;
        },
        { channel: string }
    >(MAP_SUBSCRIPTION_V2, {
        variables: {
            channel: channel,
        },
        context: { clientName: "rust" },
        fetchPolicy: "no-cache",
    });

    const { data: filteredData } = useSubscription<
        {
            listenToMapChangesStringNew: SubscriptionStringResponse;
        },
        { channel: string }
    >(NEW_MAP_SUBSCRIPTION_V2, {
        variables: {
            channel: channel,
        },
        context: { clientName: "node" },
        fetchPolicy: "no-cache",
    });

    const dispatch = useDispatch();
    const { dispatch: mapDispatch } = useMap();

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        if (data?.listenToMapChangesString) {
            dispatch(
                commonActions.setResponseParseStartTime({
                    databaseId: data.listenToMapChangesString.databaseId,
                    startTime: Date.now(),
                })
            );
            mapDispatch?.({
                type: "SET_DATA_LOADING",
                values: { state: true },
            });
            const parsed = subscriptionStringToSubscriptionResponse(
                data.listenToMapChangesString
            );
            dispatch(
                commonActions.setResponseParseEndTime({
                    databaseId: parsed.databaseId,
                    endTime: Date.now(),
                })
            );
            mapDispatch?.({
                type: "SET_DATA_LOADING",
                values: { state: false },
            });
            return onDataChange(parsed);
        }
    }, [data?.listenToMapChangesString]);

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        if (filteredData?.listenToMapChangesStringNew) {
            const data = subscriptionStringToSubscriptionResponseNew(
                filteredData.listenToMapChangesStringNew
            );

            return onDataChange(data);
        }
    }, [filteredData?.listenToMapChangesStringNew]);

    return { data: undefined };
};

const namespace = "/rbg";
const socketIOUrl = serverLocation.includes("local")
    ? `localhost:5001${namespace}`
    : `${serverLocation}${namespace}`;
//const socket = io(socketioURL);
const socket = io(socketIOUrl, {
    path: "/velocity.io",
    transports: ["websocket"],
}); // Replace with your NestJS server URL

export const useServerWebsocket = () => {
    const dispatch = useDispatch();
    useEffect(() => {
        function onConnect() {
            dispatch(
                toasterActions.openToast({
                    open: true,
                    severity: Severity.success,
                    title: "BigGeo Connected!",
                    message: "Connected",
                    autoHideDuration: 2000,
                })
            );
        }
        function onDisconnect() {
            dispatch(
                toasterActions.openToast({
                    open: true,
                    severity: Severity.error,
                    title: "BigGeo Disconnected",
                    message: "Disconnected. Retrying ...",
                    autoHideDuration: null,
                })
            );
        }
        function onError() {
            dispatch(
                toasterActions.openToast({
                    open: true,
                    severity: Severity.error,
                    title: "BigGeo Error Connecting",
                    message: "Unable to connect. Please try again soon ...",
                    autoHideDuration: null,
                })
            );
        }

        socket.on("connect", onConnect);
        socket.on("disconnect", onDisconnect);
        socket.on("error", onError);

        return () => {
            socket.off("connect", onConnect);
            socket.off("disconnect", onDisconnect);
            socket.off("error", onDisconnect);
            // Clean up the socket connection when the component unmounts
        };
    }, [dispatch]);

    return socket;
};
