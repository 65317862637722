import {
    FilterObject,
    GeometryId,
    SubscriptionResponse,
} from "@biggeo/bg-server-lib/datascape-ai";
import * as A from "fp-ts/lib/Array";
import { pipe } from "fp-ts/lib/function";
import isEmpty from "lodash/isEmpty";
import isEqual from "lodash/isEqual";
import isNil from "lodash/isNil";
import { useEffect } from "react";
import { MapFilterCriteriaStyle } from "../../filter-criteria/utils/utils";

import { bgLongToString } from "@biggeo/bg-utils";
import { point } from "@turf/turf";
import { getHeatmapFormattedValue } from "../../utils/style-utils";
import { MapContextDataset, MapContextFilter, useMap } from "../context";
import {
    getDatasetStyles,
    setDatasetVisibility,
    setPointPulsingDot,
} from "../utils/data-layers-utils";
import { handleFilterLayers } from "../utils/filtered-data-layers-utils";
import { CustomShapeSource } from "./style-hooks";

export type FilteredDataHookProps = {
    readonly map: React.MutableRefObject<mapboxgl.Map | null>;
    readonly filters: MapContextFilter[];
    readonly multiFilters: FilterObject[];
    readonly recentResponse?: SubscriptionResponse;
    readonly style?: mapboxgl.Style;
    readonly isLoaded: boolean;
    readonly isFilterCriteriaOpen: boolean;
    readonly selectedDatasets: MapContextDataset[];
};

export const useFilteredData = ({
    map,
    recentResponse,
    isLoaded,
    multiFilters,
    selectedDatasets,
}: FilteredDataHookProps) => {
    const { datasets } = useMap();
    // MARK: Filter Criteria code is hidden for now as per Jose's request.
    // const visibleFilters = pipe(
    //     filters,
    //     A.filter((f) => isEqual(f.visible, true) && !isEmpty(f.filterCriteria))
    // );

    // const selectedFilters = pipe(
    //     filters,
    //     A.filter((f) => isEqual(f.selected, true))
    // );

    const filteredDatasets = pipe(
        selectedDatasets,
        A.filter((d) => !isEmpty(d.filters.filters))
    );

    const updatedDatasets = pipe(
        selectedDatasets,
        A.filter((d) => !isEmpty(d.filters.filters) || !isEmpty(d.selectedRows))
    );

    const filters = pipe(
        selectedDatasets,
        A.filter((f) => !isEmpty(f.filters)),
        A.flatMap((f) => f.filters?.filters || [])
    );

    const setFiltersFromResponse = (input: {
        suffix: string;
        response: SubscriptionResponse;
        map: mapboxgl.Map;
        sources: {
            points: string;
            aggregate: string;
        };
        currentStyles?: Partial<MapFilterCriteriaStyle>;
        addedStyles?: Partial<MapFilterCriteriaStyle>;
        isLoaded: boolean;
        hierarchy?: Partial<{
            previousFilterId: string;
            nextFilterId: string;
        }>;
    }) => {
        const {
            suffix,
            response,
            map,
            sources,
            addedStyles,
            currentStyles,
            isLoaded,
            hierarchy,
        } = input;

        const data = JSON.parse(response.data);
        const datasetId = response.databaseId;
        const dataset = selectedDatasets.find((d) =>
            isEqual(d.dataSource.id, datasetId)
        );

        const levelSets = response.geometry?.levelSets || [];

        if (isNil(data.filtered) && isNil(data.filteredPoints)) {
            setDatasetVisibility({
                map,
                prefix: datasetId,
                levelSets,
                visibility: "visible",
                isLoaded,
                styles: dataset?.mapTemplateDataset?.styles || undefined,
            });
        } else {
            const filtered = data.filtered || [];
            const filteredPoints = data.filteredPoints || [];

            const filteredAvgPoints = filtered.map(
                (d: { LON: number; LAT: number; COUNT: number }) =>
                    point([d.LON, d.LAT], {
                        count: d.COUNT,
                        databaseId: response.databaseId,
                        label: "Filtered Aggregate",
                    })
            );

            const filteredAggPoints = filteredPoints.map(
                (d: { LON: number; LAT: number; COUNT: number }) =>
                    point([d.LON, d.LAT], {
                        count: d.COUNT,
                        databaseId: response.databaseId,
                        label: "Filtered Aggregate",
                    })
            );

            const filteredDataPoints = filteredPoints.map(
                (d: { LON: number; LAT: number; ID: GeometryId }) => {
                    const id = d.ID.uint64
                        ? bgLongToString(d.ID.uint64)
                        : d.ID.string;

                    return point([d.LON, d.LAT], {
                        id: JSON.stringify(d.ID),
                        databaseId: response.databaseId,
                        label: `Filtered Point ${id}`,
                        count: 1,
                    });
                }
            );

            handleFilterLayers({
                action: "add",
                map: { current: map },
                isLoaded,
                suffix,
                dataset,
                addedStyles,
                currentStyles,
                hierarchy,
                sources,
                sourcesData: {
                    aggregate: [...filteredAvgPoints, ...filteredAggPoints],
                    points: [...filteredDataPoints],
                },
            });
        }
    };

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        if (
            recentResponse?.data &&
            map.current &&
            isLoaded &&
            !isEmpty(filteredDatasets)
        ) {
            for (const [index, dataset] of filteredDatasets.entries()) {
                const suffix = recentResponse.databaseId;
                const isGettingFiltered =
                    dataset.dataSource.id === recentResponse.databaseId &&
                    multiFilters.some(
                        (m) => m.databaseId === recentResponse.databaseId
                    );

                if (isGettingFiltered) {
                    const isFirst = isEqual(index, 0);
                    const isLast = isEqual(index, filters.length - 1);

                    const heatmap =
                        dataset.mapTemplateDataset?.styles?.dataAggregation
                            ?.heatmap;

                    const styles = {
                        ...getDatasetStyles(dataset),
                        dataAggregation: heatmap
                            ? {
                                  heatmap: getHeatmapFormattedValue(heatmap),
                              }
                            : undefined,
                    };

                    setFiltersFromResponse({
                        suffix,
                        response: recentResponse,
                        map: map.current,
                        sources: {
                            points: `${CustomShapeSource.filtering}-points-${suffix}`,
                            aggregate: `${CustomShapeSource.filtering}-aggregate-${suffix}`,
                        },
                        addedStyles: styles,
                        currentStyles: styles,
                        isLoaded,
                        hierarchy: {
                            previousFilterId: isFirst
                                ? undefined
                                : filters[index - 1].id,
                            nextFilterId: isLast
                                ? undefined
                                : filters[index + 1].id,
                        },
                    });
                }
            }
        }
    }, [
        map.current,
        isLoaded,
        recentResponse?.uniqueId,
        multiFilters,
        filteredDatasets.length,
    ]);

    // biome-ignore lint/correctness/useExhaustiveDependencies: no additional dependency needed
    useEffect(() => {
        if (isEmpty(updatedDatasets) && map.current && isLoaded) {
            for (const dataset of datasets) {
                handleFilterLayers({
                    map,
                    action: "remove",
                    isLoaded,
                    suffix: dataset.dataSource.id,
                });
                setPointPulsingDot({
                    datasetId: dataset.dataSource.id,
                    action: "remove",
                    map,
                    isLoaded,
                    color:
                        dataset.mapTemplateDataset?.styles?.fill?.color ||
                        dataset.dataSource.color ||
                        undefined,
                    features: [],
                });
            }
        }
    }, [
        recentResponse?.uniqueId,
        filters,
        multiFilters,
        isLoaded,
        datasets,
        updatedDatasets.length,
    ]);

    // // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    // useEffect(() => {
    //     if (
    //         recentResponse?.data &&
    //         !isEmpty(filters) &&
    //         map.current &&
    //         isLoaded &&
    //         !isEmpty(selectedDatasets)
    //     ) {
    //         const dataset = selectedDatasets.find(
    //             (i) => i.dataSource?.id === recentResponse.databaseId
    //         );

    //         for (const [index, filter] of filters.entries()) {
    //             const suffix = filter.id;
    //             const isVisible =
    //                 isEqual(filter.visible, true) &&
    //                 !isEmpty(filter.filterCriteria);
    //             const isSelected = isEqual(filter.selected, true);

    //             if (isVisible || isSelected) {
    //                 const isFirst = isEqual(index, 0);
    //                 const isLast = isEqual(index, filters.length - 1);

    //                 setFiltersFromResponse({
    //                     suffix,
    //                     response: recentResponse,
    //                     map: map.current,
    //                     sources: {
    //                         points: `${CustomShapeSource.filtering}-points-${suffix}`,
    //                         aggregate: `${CustomShapeSource.filtering}-aggregate-${suffix}`,
    //                     },
    //                     addedStyles: filter.styles,
    //                     currentStyles: filter.styles,
    //                     isLoaded,
    //                     hierarchy: {
    //                         previousFilterId: isFirst
    //                             ? undefined
    //                             : filters[index - 1].id,
    //                         nextFilterId: isLast
    //                             ? undefined
    //                             : filters[index + 1].id,
    //                     },
    //                 });
    //             } else {
    //                 handleFilterLayers({
    //                     action: "remove",
    //                     map,
    //                     isLoaded,
    //                     suffix,
    //                     dataset,
    //                 });
    //             }
    //         }
    //     }
    // }, [
    //     filters,
    //     map.current,
    //     isLoaded,
    //     recentResponse?.uniqueId,
    //     isFilterCriteriaOpen,
    //     multiFilters,
    //     visibleFilters,
    //     selectedDatasets,
    // ]);

    // // biome-ignore lint/correctness/useExhaustiveDependencies: no additional dependency needed
    // useEffect(() => {
    //     if (
    //         isEmpty(visibleFilters) &&
    //         map.current &&
    //         isLoaded &&
    //         isEqual(isFiltering, false)
    //     ) {
    //         for (const filter of filters) {
    //             handleFilterLayers({
    //                 map,
    //                 action: "remove",
    //                 isLoaded,
    //                 suffix: filter.id,
    //             });
    //         }
    //     }
    // }, [
    //     recentResponse?.uniqueId,
    //     style,
    //     filters,
    //     isFilterCriteriaOpen,
    //     multiFilters,
    //     visibleFilters,
    //     isFiltering,
    //     isLoaded,
    //     selectedFilters,
    //     selectedDatasets,
    // ]);
};
