import { SelectableTreeMenuItem } from "@biggeo/bg-ui/lab";
import isEqual from "lodash/isEqual";
import kebabCase from "lodash/kebabCase";
import { useReducer, useRef, useState } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import { isAppRunningOnSF } from "../../common/redux/hooks";
import { Consumers } from "../../common/redux/model";
import { Routes } from "../../navigation/redux/model";
import { toasterActions } from "../../toaster/containers/redux/model";
import { getSavedAreaIdFromParams } from "../../utils/utils";
import {
    MapContext,
    MapContextDataset,
    MapContextType,
    MapReducer,
} from "../mapbox/context";
import {
    DEFAULT_VIEWPORT,
    removeMap,
    resizeMap,
} from "../mapbox/utils/map-utils";
import { FunctionType, SavedPolygonSource } from "../utils/utils";
import MapViewPage from "./MapViewPage";
import SavedViewPage from "./SavedViewPage";

export enum MapTabs {
    map = "map",
    configuration = "configuration",
    savedViews = "savedViews",
}

export enum MapTableTabs {
    map = "map",
    table = "table",
    split = "split",
}

export type MapTableTabsType = {
    readonly tab: MapTableTabs;
    readonly setTab: (t: MapTableTabs) => void;
    readonly openSaveViewPopper: boolean;
    readonly setOpenSaveViewPopper: React.Dispatch<
        React.SetStateAction<boolean>
    >;
};

export interface IMapViewWrapper {
    readonly savedViewId?: number;
    readonly currentTab?: MapTabs;
    readonly activeConsumption: Consumers;
    readonly setConsumption: (consumption: Consumers) => void;
    readonly mapTemplateId: number;
    readonly isSavedAreaForm?: boolean;
    readonly isFromMarketplace?: boolean;
    readonly datasets: MapContextDataset[];
    readonly isExporting?: boolean;
    readonly setIsExporting?: (isExporting: boolean) => void;
}

const MapViewWrapper = ({
    savedViewId,
    currentTab: _currentTab,
    mapTemplateId,
    datasets,
    ...props
}: IMapViewWrapper) => {
    const toPage = useNavigate();
    const currentTab = _currentTab ?? MapTabs.map;
    const reduxDispatch = useDispatch();
    const isRunningOnSF = isAppRunningOnSF();
    const map = useRef<mapboxgl.Map | null>(null);
    const draw = useRef<MapboxDraw | null>(null);

    const [tab, setTableTab] = useState<MapTableTabs>(MapTableTabs.map);

    const [openSaveViewPopper, setOpenSaveViewPopper] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();
    const savedAreaId = Number.parseInt(getSavedAreaIdFromParams(searchParams));

    const [filterItems, setFilterItems] = useState<SelectableTreeMenuItem[]>(
        []
    );

    const [state, dispatch] = useReducer(MapReducer, {
        map,
        draw,
        isLoaded: false,
        mapStates: [],
        selectedShapes: {
            type: "FeatureCollection",
            features: [],
        },
        filters: [],
        mapStyle: undefined,
        datasets,
        isDataLoading: false,
        isRunningOnSF,
        previews: [],
        polygons: [],
        viewport: DEFAULT_VIEWPORT,
        functionType: FunctionType.viewport,
        savedPolygons: {
            source: SavedPolygonSource.savedArea,
            polygons: [],
            isConflict: false,
        },
    });

    const handleMapTabs = (t: MapTabs) => {
        toPage(`${Routes.mapView}/${mapTemplateId}/${kebabCase(t)}`);
        reduxDispatch(
            toasterActions.openMapPopup({
                sx: { display: "none" },
            })
        );
    };

    const setTab = (
        input: { t: MapTableTabs } & Pick<
            MapContextType,
            "map" | "isLoaded" | "draw" | "dispatch"
        >
    ) => {
        const { t, ...params } = input;

        setTableTab(t);

        if (
            params.map.current &&
            savedViewId &&
            isEqual(t, MapTableTabs.table)
        ) {
            removeMap({
                isSavedViewPage: true,
                ...params,
            });
        }

        if (isEqual(t, MapTableTabs.map)) {
            params.map.current?.on("load", () => {
                resizeMap({ map: params.map, isLoaded: params.isLoaded });
            });
        }
    };

    return (
        <DndProvider backend={HTML5Backend}>
            <MapContext.Provider value={{ ...state, dispatch }}>
                {savedViewId ? (
                    <SavedViewPage
                        {...props}
                        savedViewId={savedViewId}
                        currentTab={currentTab}
                        mapTemplateId={Number(mapTemplateId)}
                        setSearchParams={setSearchParams}
                        handleMapTabs={handleMapTabs}
                        savedAreaId={savedAreaId}
                        filterItems={filterItems}
                        setFilterItems={setFilterItems}
                        datasets={state.datasets}
                        searchParams={searchParams}
                        tab={tab}
                        setTab={(t) => {
                            setTab({ t, map, isLoaded: true, draw, dispatch });
                        }}
                        openSaveViewPopper={openSaveViewPopper}
                        setOpenSaveViewPopper={setOpenSaveViewPopper}
                    />
                ) : (
                    <MapViewPage
                        {...props}
                        savedViewId={savedViewId}
                        currentTab={currentTab}
                        mapTemplateId={Number(mapTemplateId)}
                        handleMapTabs={handleMapTabs}
                        savedAreaId={savedAreaId}
                        filterItems={filterItems}
                        setFilterItems={setFilterItems}
                        datasets={state.datasets}
                        searchParams={searchParams}
                        setSearchParams={setSearchParams}
                        tab={tab}
                        setTab={(t) => {
                            setTab({ t, map, isLoaded: true, draw, dispatch });
                        }}
                        openSaveViewPopper={openSaveViewPopper}
                        setOpenSaveViewPopper={setOpenSaveViewPopper}
                    />
                )}
            </MapContext.Provider>
        </DndProvider>
    );
};

export default MapViewWrapper;
