import {
    Box,
    FlexScrollArea,
    FlexScrollAreaContainer,
} from "@biggeo/bg-ui/lab";
import { CenteredLayout } from "@biggeo/bg-ui/lab/layouts";
import { ReactNode } from "react";
import { UnAuthedHeader } from "../../components/UnAuthedHeader";

export type SignInUpLayoutProps = {
    children?: ReactNode;
};

export const SignInUpLayout = ({ children }: SignInUpLayoutProps) => {
    return (
        <FlexScrollAreaContainer
            sx={{
                height: "100dvh",
                width: "100%",
                minWidth: 0,
            }}
        >
            <Box
                sx={{
                    width: "100%",
                    background: (theme) => theme.palette.background.main,
                    borderBottom: 1,
                    borderColor: (theme) => theme.palette.stroke[100],
                    padding: 4,
                }}
            >
                <UnAuthedHeader />
            </Box>
            <FlexScrollArea sx={{ width: "100%", minWidth: 0 }}>
                <CenteredLayout
                    maxWidth={105}
                    sx={{
                        breakpoints: {
                            xs: {
                                padding: 4,
                            },
                            md: {
                                padding: 8,
                            },
                        },
                    }}
                >
                    {children}
                </CenteredLayout>
            </FlexScrollArea>
        </FlexScrollAreaContainer>
    );
};
