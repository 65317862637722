import {
    FilterObject,
    InputViewBox,
    SubscriptionResponse,
} from "@biggeo/bg-server-lib/datascape-ai";
import {
    Grid,
    HorizontalScroller,
    Stack,
    Tab,
    TabGroup,
    Tooltip,
    tabClasses,
} from "@biggeo/bg-ui/lab";
import * as A from "fp-ts/lib/Array";
import { pipe } from "fp-ts/lib/function";
import isEmpty from "lodash/isEmpty";
import startCase from "lodash/startCase";

import { MapLayoutTabs } from "@biggeo/bg-ui/lab/layouts";
import { isAppRunningOnSF } from "../../../common/redux/hooks";
import ExportContainer from "../../containers/ExportContainer";
import { InputPolygonWithId } from "../../hooks/pure-data-string-hook";
import { MapTableTabs } from "../../map-wrappers/MapViewWrapper";
import { MapContextDataset, MapContextFilter } from "../../mapbox/context";
import { SetDatasetContextType } from "../../mapbox/context/context-utils";
import { DataGridDivider } from "../components/DatasetTableHeader";
import FilterAppliedChip from "../components/FilterAppliedChip";
import MapFeedbackContainer from "../containers/MapFeedbackContainer";

export interface IMapTopbar
    extends Pick<SetDatasetContextType, "updateDataset"> {
    readonly onAddFilter: () => void;
    readonly filters: MapContextFilter[];
    readonly clearFilters: () => void;
    readonly removeFilter: (id: string) => void;
    readonly editFilter: (id: string) => void;
    readonly viewFilterTable: (id: string, isViewed: boolean) => void;
    readonly toggleFilterVisibility: (id: string, visible: boolean) => void;
    readonly viewport?: InputViewBox;
    readonly multipolygon?: readonly InputPolygonWithId[];
    readonly multiFilters: FilterObject[];
    readonly recentResponse?: SubscriptionResponse;
    readonly responses: Partial<Record<string, SubscriptionResponse | null>>;
    readonly currentTab: MapTableTabs;
    readonly setTab: (tab: MapTableTabs) => void;
    readonly datasets: MapContextDataset[];
    readonly deleteShape: (id: string) => void;
    readonly unselectBoundaries: (ids: readonly number[]) => void;
    readonly setNavTab: (tab: MapLayoutTabs) => void;
}

const MapTopbar = ({
    responses,
    recentResponse,
    viewport,
    multipolygon,
    multiFilters,
    currentTab,
    setTab,
    datasets,
    updateDataset,
    deleteShape,
    unselectBoundaries,
    setNavTab,
}: IMapTopbar) => {
    // MARK: Filter Criteria code is hidden for now as per Jose's request.
    const isSf = isAppRunningOnSF();
    // const hasFilters =
    //     !isEmpty(filters) && every(filters, (f) => isEqual(f.preview, false));
    // const isMobile = useMediaQuery((theme: Theme) =>
    //     theme.breakpoints.down("md")
    // );
    // const isFilterGettingEdited = some(
    //     filters,
    //     (f) => isEqual(f.preview, true) && isEqual(f.selected, true)
    // );

    const filteredDatasets = pipe(
        datasets,
        A.filter((d) => d.isSelected && !isEmpty(d.filters.filters))
    );

    const selectedDatasets = pipe(
        datasets,
        A.filter((d) => d.isSelected)
    );

    const hasFilters = !pipe(filteredDatasets, isEmpty);

    return (
        <Grid
            container
            justifyContent="space-between"
            width="100%"
            sx={{
                padding: 2,
            }}
        >
            <Grid item minWidth={0}>
                <Stack flexDirection="row" gap={2}>
                    <HorizontalScroller>
                        <TabGroup
                            variant="tonal"
                            color="surface"
                            value={currentTab}
                            density="dense"
                        >
                            {Object.values(MapTableTabs).map((tab) => (
                                <Tooltip
                                    key={tab}
                                    title={
                                        isEmpty(datasets)
                                            ? "Enable datasets to view table"
                                            : "Select datasets to view table"
                                    }
                                    disableHover={
                                        tab === MapTableTabs.map ||
                                        !isEmpty(selectedDatasets)
                                    }
                                >
                                    <span>
                                        <Tab
                                            onClick={() => setTab(tab)}
                                            value={tab}
                                            disabled={
                                                (tab === MapTableTabs.table ||
                                                    tab ===
                                                        MapTableTabs.split) &&
                                                isEmpty(selectedDatasets)
                                            }
                                            sx={{
                                                flexShrink: 0,
                                                [`&.${tabClasses.disabled}`]: {
                                                    [`&.${tabClasses.tonal}`]: {
                                                        backgroundColor: (
                                                            theme
                                                        ) =>
                                                            theme.palette
                                                                .background
                                                                .main,
                                                    },
                                                },
                                            }}
                                        >
                                            {startCase(tab)}
                                        </Tab>
                                    </span>
                                </Tooltip>
                            ))}
                        </TabGroup>
                        {hasFilters && (
                            <Stack
                                flexDirection="row"
                                alignItems="center"
                                gap={2}
                            >
                                <DataGridDivider />
                                <FilterAppliedChip
                                    datasets={filteredDatasets}
                                    updateDataset={updateDataset}
                                />
                            </Stack>
                        )}
                        <MapFeedbackContainer
                            deleteShape={deleteShape}
                            unselectBoundaries={unselectBoundaries}
                            setNavTab={setNavTab}
                        />
                    </HorizontalScroller>
                </Stack>
            </Grid>

            {/* <Grid item xs minWidth={0}>
                <HorizontalScroller>
                    <Stack flexDirection="row" gap={2}>
                        {pipe(
                            filters,
                            A.filter((f) => {
                                const isNotPreview =
                                    isEqual(f.preview, false) &&
                                    !isEmpty(f.filterCriteria);
                                const isEdit =
                                    isEqual(f.preview, true) &&
                                    isEqual(f.selected, true);

                                return isNotPreview || isEdit;
                            }),
                            A.map((filter) => (
                                <FilterCriteriaChip
                                    key={filter.id}
                                    id={filter.id}
                                    label={filter.details.name}
                                    description={filter.details.description}
                                    color={
                                        filter.styles?.fill ||
                                        DEFAULT_SHAPE_COLOR_OBJECT
                                    }
                                    gradient={
                                        filter.styles.dataAggregation?.heatmap
                                            ? getLinearGradient(
                                                  filter.styles.dataAggregation
                                                      .heatmap
                                              )
                                            : undefined
                                    }
                                    borderColor={
                                        filter.styles?.stroke ||
                                        DEFAULT_SHAPE_COLOR_OBJECT
                                    }
                                    visible={filter.visible}
                                    disabled={filter.disabled}
                                    selected={filter.selected}
                                    onVisibilityClick={(id) => {
                                        toggleFilterVisibility(
                                            id,
                                            !filter.visible
                                        );
                                    }}
                                    onDeleteClick={(id) => {
                                        removeFilter(id);
                                    }}
                                    onEditClick={(id) => editFilter(id)}
                                    onViewTable={(id) =>
                                        viewFilterTable(
                                            id,
                                            filter.viewed || false
                                        )
                                    }
                                />
                            ))
                        )}
                    </Stack>
                </HorizontalScroller>
            </Grid> */}
            <Grid item>
                <Stack
                    alignItems="center"
                    gap={2}
                    width="100%"
                    flexWrap="nowrap"
                    flexDirection="row"
                    justifyContent="flex-end"
                >
                    {/* {hasFilters && (
                        <Divider
                            orientation="vertical"
                            color={200}
                            sx={{
                                height: (theme) => theme.spacing(4),
                                marginBlockStart: isMobile
                                    ? undefined
                                    : "revert",
                                ...(isMobile && {
                                    display: "flex",
                                    alignSelf: "center",
                                }),
                            }}
                        />
                    )} */}
                    {/* <Stack
                        alignItems="center"
                        gap={2}
                        flexDirection={isMobile ? "column-reverse" : "row"}
                    >
                        <Button
                            variant="ghost"
                            density="dense"
                            disabled={!hasFilters}
                            onClick={clearFilters}
                        >
                            Clear Filters
                        </Button>
                        <DashedChip
                            text="Add Filter"
                            onClick={onAddFilter}
                            disabled={isFilterGettingEdited}
                        />
                    </Stack> */}
                    {isSf && (
                        <Grid item flexShrink={0}>
                            <ExportContainer
                                responses={responses}
                                recentResponse={recentResponse}
                                viewport={viewport}
                                multipolygon={multipolygon}
                                multiFilters={multiFilters}
                            />
                        </Grid>
                    )}
                </Stack>
            </Grid>
        </Grid>
    );
};

export default MapTopbar;
