import { isAuthenticated } from "@biggeo/bg-common";
import identity from "lodash/identity";
import React, { Suspense } from "react";
import { Route, RouterProvider, createRoutesFromElements } from "react-router";
import { createBrowserRouter } from "react-router-dom";

import { AsyncErrorProvider } from "./common/components/AsyncErrorProvider";
import DatascapeMainLayout from "./common/layouts/DatascapeMainLayout";
import { FullMapLayout } from "./common/layouts/FullMapLayout";
import {
    isAppRunningOnSF,
    useAppRunningOnSF,
    useHasBgVelocity,
    useIsSnp,
} from "./common/redux/hooks";
import { MapTabs } from "./map/map-wrappers/MapViewWrapper";
import { Routes as AppRoutes } from "./navigation/redux/model";
import { SignInUpLayout } from "./user/components/SignInUpLayout";
import { useAuthenticatedUser } from "./user/redux/hooks";

const ErrorPage = React.lazy(() => import("./common/components/ErrorPage"));
const HomePage = React.lazy(() => import("./home/views/HomePage"));
const Testsql = React.lazy(() => import("./Testsql"));
const OnlyAvailableInSnowflakeView = React.lazy(
    () => import("./common/views/OnlyAvailableInSnowflakeView")
);
const UnlockDatasetAccessKeyPage = React.lazy(
    () => import("./data-access-keys/pages/UnlockDatasetAccessKeyPage")
);
const ManageDatasetContainer = React.lazy(
    () => import("./data-sets/containers/ManageDatasetContainer")
);
const UnIndexedManagementContainer = React.lazy(
    () => import("./data/containers/UnIndexedManagementContainer")
);
const DataManagementPage = React.lazy(
    () => import("./data/pages/DataManagementPage")
);
const CreateOrManageMapTemplatePage = React.lazy(
    () => import("./map-templates/pages/CreateOrManageMapTemplatePage")
);
const ManageSavedViewPage = React.lazy(
    () => import("./map-templates/pages/ManageSavedViewPage")
);
const MapTemplatesPage = React.lazy(
    () => import("./map-templates/pages/MapTemplatesPage")
);
const ViewAllMapTemplateSavedViewsPage = React.lazy(
    () => import("./map-templates/pages/ViewAllMapTemplateSavedViewsPage")
);
const FullmapContainer = React.lazy(
    () => import("./map/containers/FullmapContainer")
);
const MarketplaceContainer = React.lazy(
    () => import("./marketplace/containers/MarketplaceContainer")
);
const MarketplaceDatasetListingByIdContainer = React.lazy(
    () =>
        import(
            "./marketplace/containers/MarketplaceDatasetListingByIdContainer"
        )
);
const MarketplaceListViewContainer = React.lazy(
    () => import("./marketplace/containers/MarketplaceListViewContainer")
);
const ExportToSFPage = React.lazy(
    () => import("./marketplace/pages/ExportToSFPage")
);
const UpgradeToBGVelocityPage = React.lazy(
    () => import("./marketplace/pages/UpgradeToBGVelocityPage")
);
const VelocityApiKeyPage = React.lazy(
    () => import("./marketplace/pages/VelocityApiKeyPage")
);
const BGVelocityUnlocked = React.lazy(
    () => import("./marketplace/views/BGVelocityUnlocked")
);
const RecipesContainer = React.lazy(
    () => import("./recipes/containers/RecipesContainer")
);
const TeamViews = React.lazy(() => import("./team/views/TeamViews"));

const SignInContainer = React.lazy(
    () => import("./user/containers/SignInContainer")
);
const SignUpContainer = React.lazy(
    () => import("./user/containers/SignUpContainer")
);

const SettingsPage = React.lazy(() => import("./settings/pages/SettingsPage"));

const ENV_DISABLE_AUTH = "true";

const AUTHED_ROUTES: JSX.Element[] = [
    <Route
        key="*"
        path="*"
        element={
            <DatascapeMainLayout>
                <Suspense fallback={<div />}>
                    <ErrorPage
                        title="Sorry, this page doesn't exist!"
                        subtitle="It appears the page you're looking for is no longer available."
                    />
                </Suspense>
            </DatascapeMainLayout>
        }
    />,
    <Route
        key={AppRoutes.home}
        path={AppRoutes.home}
        element={
            <DatascapeMainLayout>
                <Suspense fallback={<div />}>
                    <HomePage />
                </Suspense>
            </DatascapeMainLayout>
        }
    />,
    <Route
        key={`${AppRoutes.mapView}/:mapTemplateId/map`}
        path={`${AppRoutes.mapView}/:mapTemplateId/map`}
        element={
            <FullMapLayout>
                <Suspense fallback={<div />}>
                    <FullmapContainer currentTab={MapTabs.map} />
                </Suspense>
            </FullMapLayout>
        }
    />,
    <Route
        key={`${AppRoutes.mapView}/:mapTemplateId/configuration`}
        path={`${AppRoutes.mapView}/:mapTemplateId/configuration`}
        element={
            <FullMapLayout>
                <Suspense fallback={<div />}>
                    <FullmapContainer currentTab={MapTabs.configuration} />
                </Suspense>
            </FullMapLayout>
        }
    />,
    <Route
        key={`${AppRoutes.mapView}/:mapTemplateId/saved-views`}
        path={`${AppRoutes.mapView}/:mapTemplateId/saved-views`}
        element={
            <FullMapLayout>
                <Suspense fallback={<div />}>
                    <FullmapContainer currentTab={MapTabs.savedViews} />
                </Suspense>
            </FullMapLayout>
        }
    />,
    <Route
        key={`${AppRoutes.mapView}/:mapTemplateId/configuration/:savedAreaId`}
        path={`${AppRoutes.mapView}/:mapTemplateId/configuration/:savedAreaId`}
        element={
            <FullMapLayout>
                <Suspense fallback={<div />}>
                    <FullmapContainer
                        currentTab={MapTabs.configuration}
                        isSavedAreaForm
                    />
                </Suspense>
            </FullMapLayout>
        }
    />,
    <Route
        key={`${AppRoutes.data}/:menuItem?`}
        path={`${AppRoutes.data}/:menuItem?`}
        element={
            <DatascapeMainLayout>
                <Suspense fallback={<div />}>
                    <DataManagementPage />
                </Suspense>
            </DatascapeMainLayout>
        }
    />,
    <Route
        key={`${AppRoutes.data}/index/:id`}
        path={`${AppRoutes.data}/index/:id`}
        element={
            <DatascapeMainLayout>
                <Suspense fallback={<div />}>
                    <UnIndexedManagementContainer />
                </Suspense>
            </DatascapeMainLayout>
        }
    />,
    <Route
        key={`${AppRoutes.dataManage}/:id`}
        path={`${AppRoutes.dataManage}/:id`}
        element={
            <DatascapeMainLayout>
                <Suspense fallback={<div />}>
                    <ManageDatasetContainer />
                </Suspense>
            </DatascapeMainLayout>
        }
    />,
    <Route
        key={`${AppRoutes.mapTemplateData}/:mapTemplateId`}
        path={`${AppRoutes.mapTemplateData}/:mapTemplateId`}
        element={
            <DatascapeMainLayout>
                <Suspense fallback={<div />}>
                    <DataManagementPage />
                </Suspense>
            </DatascapeMainLayout>
        }
    />,
    <Route
        key={`${AppRoutes.mapTemplates}/:tab?`}
        path={`${AppRoutes.mapTemplates}/:tab?`}
        element={
            <DatascapeMainLayout>
                <Suspense fallback={<div />}>
                    <MapTemplatesPage />
                </Suspense>
            </DatascapeMainLayout>
        }
    />,
    <Route
        key={`${AppRoutes.mapTemplatesCreate}/:dataSourceId?`}
        path={`${AppRoutes.mapTemplatesCreate}/:dataSourceId?`}
        element={
            <DatascapeMainLayout>
                <Suspense fallback={<div />}>
                    <CreateOrManageMapTemplatePage />
                </Suspense>
            </DatascapeMainLayout>
        }
    />,
    <Route
        key={`${AppRoutes.mapTemplateSavedViews}/:id`}
        path={`${AppRoutes.mapTemplateSavedViews}/:id`}
        element={
            <DatascapeMainLayout>
                <Suspense fallback={<div />}>
                    <ViewAllMapTemplateSavedViewsPage />
                </Suspense>
            </DatascapeMainLayout>
        }
    />,
    <Route
        key={`${AppRoutes.mapTemplateSavedViews}/manage/:id`}
        path={`${AppRoutes.mapTemplateSavedViews}/manage/:id`}
        element={
            <DatascapeMainLayout>
                <Suspense fallback={<div />}>
                    <ManageSavedViewPage />
                </Suspense>
            </DatascapeMainLayout>
        }
    />,
    <Route
        key={`${AppRoutes.mapTemplatesManage}/:id`}
        path={`${AppRoutes.mapTemplatesManage}/:id`}
        element={
            <DatascapeMainLayout>
                <Suspense fallback={<div />}>
                    <CreateOrManageMapTemplatePage />
                </Suspense>
            </DatascapeMainLayout>
        }
    />,
    <Route
        key={`${AppRoutes.velocity}`}
        path={`${AppRoutes.velocity}`}
        element={
            <DatascapeMainLayout>
                <Suspense fallback={<div />}>
                    <UpgradeToBGVelocityPage />
                </Suspense>
            </DatascapeMainLayout>
        }
    />,
    <Route
        key={`${AppRoutes.velocityApiKey}`}
        path={`${AppRoutes.velocityApiKey}`}
        element={
            <DatascapeMainLayout>
                <Suspense fallback={<div />}>
                    <VelocityApiKeyPage />
                </Suspense>
            </DatascapeMainLayout>
        }
    />,
    <Route
        key={`${AppRoutes.export}`}
        path={`${AppRoutes.export}`}
        element={
            <DatascapeMainLayout>
                <Suspense fallback={<div />}>
                    <ExportToSFPage />
                </Suspense>
            </DatascapeMainLayout>
        }
    />,
    <Route
        key={`${AppRoutes.unlocked}`}
        path={`${AppRoutes.unlocked}`}
        element={
            <DatascapeMainLayout>
                <Suspense fallback={<div />}>
                    <BGVelocityUnlocked />
                </Suspense>
            </DatascapeMainLayout>
        }
    />,
    <Route key="/sql" path="/sql" element={<Testsql />} />,
    <Route
        key={`${AppRoutes.explore}/:menuItem?`}
        path={`${AppRoutes.explore}/:menuItem?`}
        element={
            <DatascapeMainLayout>
                <Suspense fallback={<div />}>
                    <MarketplaceContainer />
                </Suspense>
            </DatascapeMainLayout>
        }
    />,
    <Route
        key={AppRoutes.accessKeys}
        path={AppRoutes.accessKeys}
        element={
            <DatascapeMainLayout>
                <Suspense fallback={<div />}>
                    <UnlockDatasetAccessKeyPage />
                </Suspense>
            </DatascapeMainLayout>
        }
    />,
    <Route
        key={`${AppRoutes.explore}/:tabItem/:menuItem?`}
        path={`${AppRoutes.explore}/:tabItem/:menuItem?`}
        element={
            <DatascapeMainLayout>
                <Suspense fallback={<div />}>
                    <MarketplaceListViewContainer />
                </Suspense>
            </DatascapeMainLayout>
        }
    />,
    <Route
        key={`${AppRoutes.exploreDataset}/:id`}
        path={`${AppRoutes.exploreDataset}/:id`}
        element={
            <DatascapeMainLayout>
                <Suspense fallback={<div />}>
                    <MarketplaceDatasetListingByIdContainer />
                </Suspense>
            </DatascapeMainLayout>
        }
    />,
    <Route
        key={`${AppRoutes.recipes}/:menuItem?`}
        path={`${AppRoutes.recipes}/:menuItem?`}
        element={
            <DatascapeMainLayout>
                <Suspense fallback={<div />}>
                    <RecipesContainer />
                </Suspense>
            </DatascapeMainLayout>
        }
    />,
    <Route
        key={AppRoutes.team}
        path={AppRoutes.team}
        element={
            <DatascapeMainLayout>
                <Suspense fallback={<div />}>
                    <TeamViews />
                </Suspense>
            </DatascapeMainLayout>
        }
    />,
    <Route
        key={AppRoutes.onlyInSnowflake}
        path={AppRoutes.onlyInSnowflake}
        element={
            <DatascapeMainLayout hideSidebar hideHeader>
                <Suspense fallback={<div />}>
                    <OnlyAvailableInSnowflakeView />
                </Suspense>
            </DatascapeMainLayout>
        }
    />,
    <Route
        key={AppRoutes.profileSettings}
        path={AppRoutes.profileSettings}
        element={
            <DatascapeMainLayout>
                <Suspense fallback={<div />}>
                    <SettingsPage tab={"Profile"} />
                </Suspense>
            </DatascapeMainLayout>
        }
    />,
];

const UNAUTHED_ROUTES: JSX.Element[] = [
    <Route
        key="*"
        path="*"
        element={
            <SignInUpLayout>
                <Suspense fallback={<div />}>
                    <SignInContainer />
                </Suspense>
            </SignInUpLayout>
        }
    />,
    <Route
        key={AppRoutes.signIn}
        path={AppRoutes.signIn}
        element={
            <SignInUpLayout>
                <Suspense fallback={<div />}>
                    <SignInContainer />
                </Suspense>
            </SignInUpLayout>
        }
    />,
    <Route
        key={AppRoutes.signUp}
        path={AppRoutes.signUp}
        element={
            <SignInUpLayout>
                <Suspense fallback={<div />}>
                    <SignUpContainer />
                </Suspense>
            </SignInUpLayout>
        }
    />,
];

const useRoutes = () => {
    const user = useAuthenticatedUser();

    const DISABLE_AUTH = isAppRunningOnSF() || ENV_DISABLE_AUTH === "true";

    if (DISABLE_AUTH) {
        return AUTHED_ROUTES;
    }

    if (isAuthenticated(user)) {
        return [...AUTHED_ROUTES, ...UNAUTHED_ROUTES];
    }

    return UNAUTHED_ROUTES;
};

const Routes = () => {
    useAppRunningOnSF();
    useIsSnp();
    useHasBgVelocity();

    const routesList = useRoutes();

    const RoutesComponent = <>{routesList.map(identity)}</>;

    const router = createBrowserRouter(
        createRoutesFromElements(RoutesComponent)
    );

    return (
        <AsyncErrorProvider>
            <RouterProvider router={router} />
        </AsyncErrorProvider>
    );
};

export default Routes;
