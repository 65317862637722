import {
    BreakpointSwapper,
    Button,
    Grid,
    IconButton,
    Menu,
    MenuItem,
} from "@biggeo/bg-ui/lab";
import {
    BigGeoHorizontalLogo,
    MailOutline,
    MenuOutline,
} from "@biggeo/bg-ui/lab/icons";
import { BigGeoLink } from "../common/components/BigGeoLink";
import { Routes } from "../navigation/redux/model";

enum ExternalLinks {
    docs = "https://docs.biggeo.com/reference/introduction",
    contactSales = "https://biggeo.com/deploy/contact-sales",
    joinBigGeoDiscord = "https://discord.gg/zPFRwkRNjw",
}

export const UnAuthedHeader = () => {
    return (
        <Grid
            container
            alignItems="center"
            flexWrap="nowrap"
            justifyContent="space-between"
            gap={2}
        >
            <Grid item>
                <BigGeoLink href={Routes.home}>
                    <BigGeoHorizontalLogo height={7} width="auto" />
                </BigGeoLink>
            </Grid>

            <BreakpointSwapper
                item
                desktop={
                    <Grid
                        container
                        alignItems="center"
                        flexWrap="nowrap"
                        gap={2}
                    >
                        {/* <BigGeoLink tabIndex={-1} href={Routes.marketplace}>
                            <Button variant="ghost">Marketplace</Button>
                        </BigGeoLink> */}
                        {/* <BigGeoLink
                            tabIndex={-1}
                            variant="new-tab"
                            href={ExternalLinks.docs}
                        >
                            <Button variant="ghost">Read The Docs</Button>
                        </BigGeoLink> */}
                        <BigGeoLink
                            tabIndex={-1}
                            variant="new-tab"
                            href={ExternalLinks.contactSales}
                        >
                            <Button color="primary" rounded density="dense">
                                Contact Sales
                            </Button>
                        </BigGeoLink>
                    </Grid>
                }
                mobile={
                    <Menu
                        content={
                            <>
                                {/* <BigGeoLink
                                    tabIndex={-1}
                                    href={Routes.marketplace}
                                >
                                    <MenuItem
                                        label="Marketplace"
                                        startNode={<ActionKeyOutline />}
                                    />
                                </BigGeoLink>
                                <BigGeoLink
                                    tabIndex={-1}
                                    href={ExternalLinks.docs}
                                >
                                    <MenuItem
                                        label="Read The Docs"
                                        startNode={<UnknownDocumentOutline />}
                                    />
                                </BigGeoLink> */}
                                <BigGeoLink
                                    tabIndex={-1}
                                    href={ExternalLinks.contactSales}
                                >
                                    <MenuItem
                                        label="Contact Sales"
                                        startNode={<MailOutline />}
                                    />
                                </BigGeoLink>
                            </>
                        }
                    >
                        <IconButton variant="outlined" density="dense">
                            <MenuOutline />
                        </IconButton>
                    </Menu>
                }
            />
        </Grid>
    );
};
