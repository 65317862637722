import { isAuthenticated } from "@biggeo/bg-common";
import { MainLayout } from "@biggeo/bg-ui/lab/layouts";
import React, { useEffect, useReducer, useState } from "react";
import { useLocation } from "react-router-dom";
import { AuthedHeader } from "../../components/AuthedHeader";
import { Sidebar } from "../../components/Sidebar";
import { DataAccessKeyFormPanel } from "../../data-access-keys/components/DataAccessKeyFormPanel";
import { NavigationContext, NavigationReducer } from "../../map/mapbox/context";
import { useAuthenticatedUser } from "../../user/redux/hooks";
import { isAppRunningOnSF } from "../redux/hooks";

const ENV_DISABLE_AUTH = import.meta.env.VITE_DISABLE_AUTH as string;

const DatascapeMainLayout = ({
    children,
    hideSidebar = false,
    hideHeader = false,
}: {
    readonly children: React.ReactNode;
    readonly hideSidebar?: boolean;
    readonly hideHeader?: boolean;
}) => {
    const user = useAuthenticatedUser();

    const DISABLE_AUTH = isAppRunningOnSF() || ENV_DISABLE_AUTH === "true";

    const location = useLocation();
    const [state, dispatch] = useReducer(NavigationReducer, {
        location,
    });
    const [sidebarOpen, setSidebarOpen] = useState(false);

    useEffect(() => {
        dispatch({ type: "SET_LOCATION", values: { location } });
    }, [location]);

    return (
        <NavigationContext.Provider value={{ ...state, dispatch }}>
            <MainLayout
                header={
                    <AuthedHeader
                        openSidebar={() => setSidebarOpen(!sidebarOpen)}
                        authenticatedUser={
                            isAuthenticated(user) && !DISABLE_AUTH
                                ? user
                                : undefined
                        }
                    />
                }
                mainSidebar={<Sidebar isTemporary />}
                sidebarOpen={sidebarOpen}
                setSidebarOpen={setSidebarOpen}
                hideSidebar={hideSidebar}
                hideHeader={hideHeader}
            >
                {children}
                <DataAccessKeyFormPanel />
            </MainLayout>
        </NavigationContext.Provider>
    );
};

export default DatascapeMainLayout;
