import {
    AnonymousUser,
    Applications,
    AuthenticatingUser,
    __AuthenticatedUser,
    getCookies,
} from "@biggeo/bg-common";
import { useValidateJwtMutation } from "@biggeo/bg-server-lib/datascape-ai";
import { Failure, Success, equals, match } from "@vividtheory/remotedata";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import Cookies from "universal-cookie";

import { RootState } from "../../redux/reducer";
import { userActions } from "./model";

const anonymous = new AnonymousUser();
const validating = new AuthenticatingUser();

export const useAuthenticatedUser = () => {
    const userRemoteData = useSelector(
        (state: RootState) => state.user.userRemoteData,
        equals
    );
    const dispatch = useDispatch();

    const {
        executeMutation: validateJwt,
        mutationReturn: [_j, { loading }],
    } = useValidateJwtMutation();

    return match(userRemoteData, {
        Initialized: () => {
            const cookies = new Cookies();
            const appCookies = getCookies(Applications.datascape);
            const jwt = cookies.get(appCookies.jwt);

            if (jwt) {
                validateJwt({
                    onCompleted: (data) => {
                        dispatch(
                            userActions.onAuthenticationValidation({
                                v: new Success(data.validateJwt),
                                type: "success",
                            })
                        );
                    },
                    onError: (error) => {
                        dispatch(
                            userActions.onAuthenticationValidation({
                                v: new Failure(error.message),
                                type: "failure",
                            })
                        );
                    },
                });

                if (loading) {
                    dispatch(
                        userActions.onAuthenticationValidation({
                            type: "request",
                        })
                    );
                    return validating;
                }
            }
            return anonymous;
        },
        Pending: () => validating,
        Failure: () => anonymous,
        Success: (u) => new __AuthenticatedUser(u),
    });
};

export const useLogout = () => {
    const dispatch = useDispatch();
    return useCallback(() => dispatch(userActions.remove()), [dispatch]);
};
